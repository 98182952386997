import React, { useEffect, useState } from "react";
import ChartComponent from "./ChartComponent";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import Counter from "./Counter";
import { addDays, endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import { getUserMetrics } from "../actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { USER_METRICS_RESET } from "../constants/userConstants";
import Skeleton from "react-loading-skeleton";

const UserMetrics = ({ userId }) => {
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(addDays(new Date(), 1));

  const userMetricsStore = useSelector((state) => state.userMetricsStore);
  const { loading, metrics, error } = userMetricsStore;

  const metricsRange = [
    {
      index: 0,
      title: format(subMonths(new Date(), 0), "MMMM yyyy"),
      start: startOfMonth(subMonths(new Date(), 0)),
      end: addDays(new Date(), 1),
    },
    {
      index: 1,
      title: format(subMonths(new Date(), 1), "MMMM yyyy"),
      start: startOfMonth(subMonths(new Date(), 1)),
      end: startOfMonth(subMonths(new Date(), 0)),
    },
    {
      index: 2,
      title: format(subMonths(new Date(), 2), "MMMM yyyy"),
      start: startOfMonth(subMonths(new Date(), 2)),
      end: startOfMonth(subMonths(new Date(), 1)),
    },
  ];

  const dispatch = useDispatch();

  const rangHandling = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    setStartDate(selectedOption.value);
    setEndDate(selectedOption.getAttribute("data-secondary"));
    dispatch({ type: USER_METRICS_RESET });
  };

  useEffect(() => {
    if (!metrics) {
      dispatch(
        getUserMetrics({
          id: userId,
          startDate: startDate,
          endDate: endDate,
        })
      );
    }
  }, [metrics]);
  return (
    <div>
      <h3>Your Metrics</h3>

      <Row>
        <FormGroup className="col-12 mt-2">
          <select
            className="custom-select text-bold"
            type="text"
            onChange={(e) => rangHandling(e)}
          >
            {metricsRange.map((r) => (
              <option key={r.index} value={r.start} data-secondary={r.end}>
                {r.title}
              </option>
            ))}
          </select>
        </FormGroup>
      </Row>

      {loading && (
        <Row>
          <Col>
            <Row className="justify-content-center">
              <Skeleton
                height="75px"
                width="75px"
                className="mt-3"
                count={1}
                circle
              />
            </Row>
          </Col>

          <Col>
            <Row className="justify-content-center">
              <Skeleton
                height="75px"
                width="75px"
                className="mt-3"
                count={1}
                circle
              />
            </Row>
          </Col>
        </Row>
      )}
      {metrics && (
        <>
          <Row>
            <Col className="col-lg-6">
              <Counter limit={metrics.patients} label="Patients" />
            </Col>
            <Col className="col-lg-6">
              <Counter limit={metrics.reviews} label="Reviews" />
            </Col>
          </Row>
          <ChartComponent
            data={metrics.revMetricsPerType}
            y_axis="Err Type"
            chartTitle="Top Ten Medication Error by Error Type"
          />
        </>
      )}
    </div>
  );
};

export default UserMetrics;

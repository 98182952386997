import React, { useEffect, useState } from "react";
import UploadWidget from "../components/UploadWidget";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Image,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateAdmission } from "../actions/admissionActions";
import { ADMISSION_DETAILS_RESET } from "../constants/admissionConstants";
import Skeleton from "react-loading-skeleton";

const AdmGallery = ({ admissionId }) => {
  const [title, setTitle] = useState("");
  const [images, setImages] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [show, setShow] = useState(false);

  const admissionDetails = useSelector((state) => state.admissionDetails);
  const { admission } = admissionDetails;

  const admUpdateStore = useSelector((state) => state.admUpdateStore);
  const { loading, updatedAdmission } = admUpdateStore;

  const dispatch = useDispatch();

  const updateGallery = () => {
    dispatch(
      updateAdmission({
        _id: admissionId,
        admissionDate: admission.admissionDate,
        admissionDetails: admission.admissionDetails,
        dischargeDate: admission.dischargeDate,
        dischargeNote: admission.dischargeNote,
        dischargeStatus: admission.dischargeStatus,
        gallery: [
          ...gallery,
          {
            title,
            images,
          },
        ],
      })
    );
    setGallery([]);
    setTitle("");
    setImages([]);
  };

  const deleteHandler = (e) => {
    if (window.confirm("are you sure?")) {
      dispatch(
        updateAdmission({
          _id: admissionId,
          admissionDate: admission.admissionDate,
          admissionDetails: admission.admissionDetails,
          dischargeDate: admission.dischargeDate,
          dischargeNote: admission.dischargeNote,
          dischargeStatus: admission.dischargeStatus,
          gallery: gallery.filter((g) => g._id.toString() !== e._id.toString()),
        })
      );
      setGallery([]);
      setTitle("");
      setImages([]);
    }
  };
  useEffect(() => {
    if (updatedAdmission) {
      setGallery(updatedAdmission.gallery);
      setShow(false);
    } else {
      if (admission) {
        setGallery(admission.gallery);
      }
    }
  }, [admission, updatedAdmission]);
  return (
    <div className="my-3">
      {loading && <Skeleton height="100px" className="my-2" count={2} />}

      <Accordion>
        {admission &&
          gallery.map((i, order = 0) => (
            <div key={order + 1} className="my-2">
              <Accordion.Toggle
                eventKey={i._id}
                as={Card.Header}
                variant="primary"
              >
                <h3>{i.title}</h3>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={i._id}>
                <>
                  <Row className="justify-content-end mx-3 mt-2">
                    <Button
                      className="btn btn-danger"
                      onClick={() => deleteHandler(i)}
                    >
                      <i className="fas fa-trash-alt"></i> Delete
                    </Button>
                  </Row>
                  {i.images.map((im, index = 0) => (
                    <Row key={index + 1}>
                      <Col>
                        <Image src={im.image} fluid />
                      </Col>
                    </Row>
                  ))}
                </>
              </Accordion.Collapse>
            </div>
          ))}
      </Accordion>

      <Form className="p-4 rounded border">
        <h1 className="mb-4 text-center">Add New Images</h1>

        {loading && <Skeleton height="100px" className="my-2" />}

        {show && (
          <Row className="justify-content-center mt-4">
            <Button
              className="btn btn-danger"
              onClick={updateGallery}
              style={{ width: "fit-content" }}
            >
              Click here to complete Saving
            </Button>
          </Row>
        )}
        <Form.Group controlId="title" className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            value={title}
            placeholder="Type here to allow upload"
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>

        {title && !show && (
          <UploadWidget
            images={images}
            setImages={setImages}
            setShow={setShow}
          />
        )}
      </Form>
    </div>
  );
};

export default AdmGallery;

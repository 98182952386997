import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const ErrScreen = () => {
  const navigate = useNavigate()
  return (
    <Container>
      <Row className="justify-content-center">
        <h1 style={{ color: '#00abff' }}>
          4<i className="far fa-question-circle fa-spin"></i>4
        </h1>
      </Row>
      <Row className="justify-content-center">
        <p>
          Looks like the page you were looking for is no longer here.
          <p>
            Let's go{' '}
            <strong style={{ color: '#00abff' }} onClick={() => navigate('/')}>
              home
            </strong>{' '}
            and try from there.
          </p>
        </p>
      </Row>
    </Container>
  )
}

export default ErrScreen

const severityScale = [
  { _id: "", label: "Select", disabled: "true" },
  {
    _id: 0,
    label: "A: Circumstances or events that have the capacity to cause error",
  },
  {
    _id: 1,
    label: "B: An error occurred, but the medication did not reach the patient",
  },
  {
    _id: 2,
    label:
      "C: An error occurred that reached the patient but did not cause patient harm",
  },
  {
    _id: 3,
    label:
      "D: An error occurred that resulted in the need for increased patient monitoring but no patient harm",
  },
  {
    _id: 4,
    label:
      "E: An error occurred that resulted in the need for treatment or intervention and caused temporary patient harm",
  },
  {
    _id: 5,
    label:
      "F: An error occurred that resulted in initial or prolonged hospitalization and caused temporary patient harm",
  },
  {
    _id: 6,
    label: "G: An error occurred that resulted in permanent patient harm",
  },
  {
    _id: 7,
    label:
      "H: An error occurred that resulted in a near-death event (e.g., anaphylaxis, cardiac arrest)",
  },
  { _id: 8, label: "I: An error occurred that resulted in patient death" },
];
export default severityScale;

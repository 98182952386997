import React, { useEffect, useState } from "react";
import { ProgressBar, Row } from "react-bootstrap";

const ProgBar = ({ unitDuration }) => {
  let [progress, setProgress] = useState({ num: 0 });

  useEffect(() => {
    setInterval(() => {
      setProgress((prevStat) => {
        return {
          num: prevStat.num + 1,
        };
      });
    }, 1000 * unitDuration);
  }, []);
  return (
    <>
      <Row className="justify-content-center">
        <ProgressBar
          className="my-5"
          variant="success"
          min={0}
          max={100}
          label={`${progress.num} %`}
          now={progress.num}
          style={{ width: "75%" }}
        />
      </Row>
    </>
  );
};

export default ProgBar;

import React, { useState } from "react";
import { Form, Button, Accordion, Card, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import TextEditor from "./TextEditor";
import Message from "./Message";
import { createAdmission } from "../actions/admissionActions";

const AdmissionForm = ({ patientId }) => {
  const [admissionDate, setAdmissionDate] = useState(new Date());
  const [admissionDetails, setAdmissionDetails] = useState(
    "<p>Hx of hypersensitivity?</p>"
  );
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();

  const patientDetails = useSelector((state) => state.patientDetails);
  const { patient } = patientDetails;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createAdmission({
        patient: patientId,
        admissionDate: admissionDate,
        admissionDetails,
        team: patient.team,
      })
    );
  };

  return (
    <>
      {message && <Message variant="danger">{message}</Message>}

      <Accordion defaultActiveKey="0">
        <Accordion.Toggle
          className="bg-secondary text-white"
          eventKey="0"
          as={Card.Header}
          variant="link"
        >
          <Row className="justify-content-center">
            <i className="fas fa-angle-double-down pt-1 pr-2"></i>CREATE NEW
            ADMISSION
          </Row>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="admissionDate">
              <Form.Label>
                <h2>Admission Date</h2>
              </Form.Label>
              <Form.Control
                type="date"
                value={admissionDate}
                onChange={(e) => setAdmissionDate(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <div className="text-dark">
              <Form.Label>
                <h2>Clinical Details</h2>
              </Form.Label>

              <TextEditor
                text={admissionDetails}
                setText={setAdmissionDetails}
              />
            </div>
            <Form.Group className="py-3">
              <Button className="btn btn-info float-right" type="submit">
                Add New Admission
              </Button>
            </Form.Group>
          </Form>
        </Accordion.Collapse>
      </Accordion>
    </>
  );
};

export default AdmissionForm;

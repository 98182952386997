import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getRootViaTradeId } from "../actions/drugActions";
import DrugForm from "../components/DrugForm";
import Loader from "../components/Loader";

const DrugAdminScreen = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const reqTrade = searchParams.get("tradeId");

  const rootByTradeIdStore = useSelector((state) => state.rootByTradeIdStore);
  const { loading, drug } = rootByTradeIdStore;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else {
      if (reqTrade) {
        dispatch(getRootViaTradeId(reqTrade));
      }
    }
  }, [reqTrade, userInfo, navigate]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : drug ? (
        <DrugForm title={`UPDATE DRUG DATA`} reqTrade={reqTrade} />
      ) : (
        <DrugForm title="ADD NEW DRUG" />
      )}
    </div>
  );
};

export default DrugAdminScreen;

import React from "react";

const ReviewSearchDisplay = ({ reviews, startDate, endDate }) => {
  return (
    <div>
      <div className="row text-center">
        <div className="col">
          <h3>
            Collected Intervention Report <br />
            {`From ${startDate} To ${endDate}`}
          </h3>
        </div>
      </div>
      {reviews &&
        startDate &&
        endDate &&
        reviews.map((p) => (
          <div key={p._id}>
            <div>
              <hr style={{ backgroundColor: "black", size: "30px" }} />
            </div>
            <h2 className="row justify-content-center text-bold">
              {`${p.order}-Patient Name:  ${p.firstName} ${p.middleName} ${p.lastName}`}
            </h2>

            {p.admissions.map((a) => (
              <ul key={a._id}>
                {" "}
                <li>
                  <h2>
                    {a.admissionDate &&
                      `Admission Date: ${a.admissionDate.substring(0, 10)}`}
                  </h2>
                </li>
                <h2
                  dangerouslySetInnerHTML={{
                    __html: `${a.admissionDetails}`,
                  }}
                />
                {a.dischargeDate && (
                  <li>
                    {" "}
                    <h2>{`Discharge Date: ${a.dischargeDate.substring(
                      0,
                      10
                    )}`}</h2>
                  </li>
                )}
                {a.reviews.map((r) => (
                  <div key={r._id}>
                    <div>
                      <hr
                        style={{
                          size: "30px",
                          width: "50%",
                        }}
                      />
                    </div>
                    <ul>
                      <li>
                        <h2>
                          {r.reviewDate &&
                            `Review Date: ${r.reviewDate.substring(
                              0,
                              10
                            )}, Edited by: ${r.userName} `}
                          <span>
                            {" "}
                            <i
                              onClick={() => {
                                window.open(
                                  `https://www.clinicbook.org/admissions/${a.admissionId}?review=${r._id}`,
                                  "_blank"
                                );
                              }}
                              className="fas fa-external-link-alt"
                            ></i>
                          </span>
                        </h2>
                      </li>
                    </ul>
                  </div>
                ))}
              </ul>
            ))}
          </div>
        ))}
    </div>
  );
};

export default ReviewSearchDisplay;

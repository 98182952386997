import Axios from "axios";
import {
  USER_ERR_TEMP_ADD_FAIL,
  USER_ERR_TEMP_ADD_REQUEST,
  USER_ERR_TEMP_ADD_RESET,
  USER_ERR_TEMP_ADD_SUCCESS,
  USER_ERR_TEMP_DELETE_FAIL,
  USER_ERR_TEMP_DELETE_REQUEST,
  USER_ERR_TEMP_DELETE_SUCCESS,
  USER_ERR_TEMP_FAIL,
  USER_ERR_TEMP_REQUEST,
  USER_ERR_TEMP_SUCCESS,
} from "../constants/userErrTempConstants";

export const getUserTemp = (filters) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ERR_TEMP_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await Axios.get(
      `/api/userErrTemps?userId=${filters.userId}&errType=${filters.errType}&errDrugTradeId=${filters.errDrug}`,

      config
    );

    dispatch({
      type: USER_ERR_TEMP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ERR_TEMP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const delUserTemp = (id) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ERR_TEMP_DELETE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await Axios.delete(
      `/api/userErrTemps/deleteUserErrTemp/${id}`,
      config
    );

    dispatch({
      type: USER_ERR_TEMP_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ERR_TEMP_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addUserTemp = (newTemp) => async (dispatch) => {
  try {
    dispatch({
      type: USER_ERR_TEMP_ADD_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await Axios.post(`/api/userErrTemps?`, newTemp, config);

    dispatch({
      type: USER_ERR_TEMP_ADD_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USER_ERR_TEMP_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AdmissionList from "../components/AdmissionList";
import PatientDetails from "../components/PatientDetails";
import FormContainer from "../components/FormContainer";
import Skeleton from "react-loading-skeleton";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import { Button } from "react-bootstrap";
import { deletePatient } from "../actions/patientActions";
import Loader from "../components/Loader";
import { notify } from "../components/notify";

const AdmissionListScreen = () => {
  const params = useParams();
  const patientId = params.id;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const teamDetails = useSelector((state) => state.teamDetails);
  const { team } = teamDetails;

  const admListStore = useSelector((state) => state.admListStore);
  const { admissions } = admListStore;

  const patientDetails = useSelector((state) => state.patientDetails);
  const { patient } = patientDetails;

  const patientDeleteStore = useSelector((state) => state.patientDeleteStore);
  const { loading, message } = patientDeleteStore;

  let editPermission =
    team && team.members.find((m) => m.user === userInfo.email) && userInfo
      ? team.members.find((m) => m.user === userInfo.email).canEdit
      : false;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const removePatient = () => {
    dispatch(deletePatient(patientId));
  };

  useEffect(() => {
    if (!userInfo) {
      navigate(`/?returnFrom=${location.pathname}`);
    }
    if (team && message) {
      notify({
        title: `Delete Status!`,
        message: `${message}`,
        type: "success",
        duration: 1000,
      });
      navigate(`/patientList/${team._id}`);
    }
  }, [userInfo, team, navigate, message]);

  return (
    <FormContainer>
      <>
        {!patient || !team || loading ? (
          <Skeleton height="50px" className="mb-5" />
        ) : (
          <BreadcrumbComponent
            titles={[
              { idx: "1", label: "Home", path: `/`, active: false },
              {
                idx: "2",
                label: `${team.name}`,
                path: `/team/${team._id}`,
                active: false,
              },
              {
                idx: "3",
                label: `Patients`,
                path: `/patientlist/${team._id}`,
                active: false,
              },
              {
                idx: "4",
                label: `${patient.firstName} ${patient.middleName} ${patient.lastName}`,
                path: `/patient/${patient._id}`,
                active: true,
              },
            ]}
          />
        )}
        {loading ? (
          <Loader />
        ) : (
          <PatientDetails
            patientId={patientId}
            editPermission={editPermission}
          />
        )}

        {admissions && admissions.length === 0 && (
          <div className="d-grid gap-2">
            <Button
              onClick={() => removePatient()}
              className="btn-lg btn-block btn-danger my-5"
            >
              Delete Patient
            </Button>
          </div>
        )}

        {loading ? (
          <Skeleton height="50px" className="mb-5" count={2} />
        ) : (
          <AdmissionList
            patientId={patientId}
            editPermission={editPermission}
          />
        )}
      </>
    </FormContainer>
  );
};

export default AdmissionListScreen;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserTemp,
  delUserTemp,
  getUserTemp,
} from "../actions/userErrTempActions";
import {
  USER_ERR_TEMP_ADD_RESET,
  USER_ERR_TEMP_DELETE_FAIL,
  USER_ERR_TEMP_DELETE_REQUEST,
  USER_ERR_TEMP_DELETE_RESET,
  USER_ERR_TEMP_RESET,
} from "../constants/userErrTempConstants";
import {
  Button,
  ButtonToolbar,
  Container,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import Loader from "./Loader";
import TextEditor from "./TextEditor";

const ReviewUserErrTemp = ({ errDrug, errType, copyTemplate }) => {
  const [loadedTemps, setLoadedTemps] = useState("");
  const [newNote, setNewNote] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userErrTempStore = useSelector((state) => state.userErrTempStore);
  const { loading, temps } = userErrTempStore;

  const userErrTempDelStore = useSelector((state) => state.userErrTempDelStore);
  const { message } = userErrTempDelStore;

  const userErrTempAddStore = useSelector((state) => state.userErrTempAddStore);
  const { loading: loadingNewTemp, newTemp } = userErrTempAddStore;

  const dispatch = useDispatch();

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(delUserTemp(id));
    }
  };

  useEffect(() => {
    if (!loadedTemps) {
      if (temps) {
        setLoadedTemps(temps);
        dispatch({ type: USER_ERR_TEMP_RESET });
      } else {
        if (errDrug && errType) {
          dispatch(getUserTemp({ userId: userInfo._id, errDrug, errType }));
        }
      }
    } else {
      if (newTemp) {
        setLoadedTemps([...loadedTemps, newTemp]);
        dispatch({ type: USER_ERR_TEMP_ADD_RESET });
      }
      if (message) {
        dispatch({ type: USER_ERR_TEMP_DELETE_RESET });
        setLoadedTemps();
      }
    }
  }, [errDrug, errType, loadedTemps, temps, newTemp, message]);

  return (
    <div>
      {loading || loadingNewTemp ? (
        <Loader />
      ) : (
        <>
          {loadedTemps &&
            loadedTemps.map((t) => (
              <ListGroupItem key={t._id} className="my-3 border-success">
                <ListGroupItem
                  className="row btn btn-block m-2  btn-outline-success"
                  dangerouslySetInnerHTML={{
                    __html: `${t.errNote}`,
                  }}
                />
                <ButtonToolbar className="mt-3 d-flex justify-content-around row-hl">
                  <Button onClick={() => deleteHandler(t._id)}>Delete</Button>
                  <Button>Edit</Button>
                  <Button onClick={() => copyTemplate({ label: t.errNote })}>
                    Paste
                  </Button>
                </ButtonToolbar>
              </ListGroupItem>
            ))}
        </>
      )}

      <Container>
        <h5 className="row justify-content-center">ADD YOUR OWN NOTE </h5>
        <div className="text-dark">
          <TextEditor text={newNote} setText={setNewNote} />

          <Row className="justify-content-center ml-2 py-2">
            <Button
              onClick={() =>
                dispatch(
                  addUserTemp({
                    userId: userInfo._id,
                    errDrugTradeId: errDrug,
                    errType,
                    errNote: newNote,
                  })
                )
              }
            >
              Add
            </Button>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ReviewUserErrTemp;

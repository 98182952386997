import axios from "axios";
import {
  ERR_TEMPS_FAIL,
  ERR_TEMPS_REQUEST,
  ERR_TEMPS_SUCCESS,
  LIST_DRUG_ROOTS_FAIL,
  LIST_DRUG_ROOTS_REQUEST,
  LIST_DRUG_ROOTS_SUCCESS,
  NEW_DRUG_FAIL,
  NEW_DRUG_REQUEST,
  NEW_DRUG_SUCCESS,
  ROOT_BY_TRADEID_FAIL,
  ROOT_BY_TRADEID_REQUEST,
  ROOT_BY_TRADEID_SUCCESS,
  TRADE_LABELS_FAIL,
  TRADE_LABELS_REQUEST,
  TRADE_LABELS_SUCCESS,
  UPDATE_ROOT_FAIL,
  UPDATE_ROOT_REQUEST,
  UPDATE_ROOT_SUCCESS,
} from "../constants/drugConstants";

export const listTradeLabels = () => async (dispatch) => {
  try {
    dispatch({
      type: TRADE_LABELS_REQUEST,
    });

    const { data } = await axios.get(`/api/drugs`);
    dispatch({
      type: TRADE_LABELS_SUCCESS,
    });
    localStorage.setItem("tradeLabels", JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: TRADE_LABELS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listDrugRoots = () => async (dispatch) => {
  try {
    dispatch({
      type: LIST_DRUG_ROOTS_REQUEST,
    });

    const { data } = await axios.get(`/api/drugs/roots`);
    dispatch({
      type: LIST_DRUG_ROOTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: LIST_DRUG_ROOTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listErrTemps = (q) => async (dispatch) => {
  try {
    dispatch({
      type: ERR_TEMPS_REQUEST,
    });

    const { data } = await axios.get(
      `/api/drugs/errtemps?trade=${q.tradeNameId}&errType=${q.errTypeId}`
    );
    dispatch({
      type: ERR_TEMPS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ERR_TEMPS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addNewDrug = (d) => async (dispatch, getState) => {
  try {
    dispatch({
      type: NEW_DRUG_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/drugs`, d, config);
    dispatch({
      type: NEW_DRUG_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_DRUG_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getRootViaTradeId = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ROOT_BY_TRADEID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/drugs/tradeId/${id}`, config);
    dispatch({
      type: ROOT_BY_TRADEID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ROOT_BY_TRADEID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateRoot = (newRoot) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_ROOT_REQUEST,
    });

    // const {
    //   userLogin: { userInfo },
    // } = getState()

    // const config = {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${userInfo.token}`,
    //   },
    // }

    const { data } = await axios.put(`/api/drugs`, newRoot);
    dispatch({
      type: UPDATE_ROOT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_ROOT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

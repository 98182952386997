import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

import TextEditor from "./TextEditor";

import Skeleton from "react-loading-skeleton";
import { deletePhysicNote, updatePhysicNote } from "../actions/physNoteAction";

const PhysicNoteEditForm = ({ noteData, setShowEdit }) => {
  const [noteId, setNoteId] = useState();
  const [noteDate, setNoteDate] = useState();
  const [note, setNote] = useState();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updatePhysicNote({
        noteId,
        userId: userInfo._id,
        noteDate,
        note,
      })
    );
    setShowEdit(false);
  };

  const deleteHandler = () => {
    if (noteData) {
      dispatch(deletePhysicNote(noteId));
      setShowEdit(false);
    }
  };

  useEffect(() => {
    if (noteData) {
      setNoteId(noteData._id);
      setNoteDate(noteData.noteDate && noteData.noteDate.substring(0, 10));
      setNote(noteData.note);
    }
  }, [noteData]);
  return (
    <Card className="border-primary">
      <Form className="p-3">
        <Form.Group>
          <Form.Label>
            <h2>Note Date</h2>
          </Form.Label>

          {!noteDate ? (
            <Skeleton />
          ) : (
            <Form.Control
              type="date"
              value={noteDate}
              onChange={(e) => setNoteDate(e.target.value)}
            ></Form.Control>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>
            <h2>Clinical Notes</h2>
          </Form.Label>

          <div className="text-dark">
            {" "}
            <TextEditor text={noteData.note} setText={setNote} />
          </div>
        </Form.Group>

        <Row>
          <Col className="col-sm-4 my-1 ">
            <Row className="justify-content-center">
              <Button onClick={() => deleteHandler()} className="btn-danger ">
                Delete
              </Button>
            </Row>
          </Col>
          <Col className="col-sm-4 my-1 ">
            <Row className="justify-content-center">
              <Button
                onClick={(e) => submitHandler(e)}
                className="btn-success "
              >
                Update
              </Button>
            </Row>
          </Col>

          <Col className="col-sm-4 my-1">
            <Row className="justify-content-center">
              <Button
                className="btn-secondary "
                onClick={() => setShowEdit(false)}
              >
                Ignore
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default PhysicNoteEditForm;

import React, { useEffect, useState } from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Test = () => {
  const [showButtons, setShowButtons] = useState(true);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const updateServerDrugRoot = async () => {
    setShowButtons(false);
    try {
      await axios.get(`/api/drugs/roots`);
    } catch (error) {
      console.log(error);
    }
  };

  const stopClosedAdmMed = async () => {
    setShowButtons(false);
    try {
      await axios.get(`/api/admissions/stopMedication`);
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else {
      if (userInfo.email !== "admin@imc.com") {
        navigate("/*");
      }
    }
  }, []);
  return (
    <>
      {showButtons && (
        <ListGroup>
          <ListGroupItem className="btn-lg">
            <div onClick={() => updateServerDrugRoot()}>Update Trade List</div>
          </ListGroupItem>
          <ListGroupItem className="my-3 btn-lg">
            <div onClick={() => stopClosedAdmMed()}>Stop Closed AdmMed</div>
          </ListGroupItem>
        </ListGroup>
      )}
    </>
  );
};

export default Test;

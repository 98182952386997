import React from "react";
import getPageMargins from "../components/getPageMargins";

export const ComponentToPrint = React.forwardRef(({ review, patient }, ref) => {
  return (
    <div
      className="bg-white text-dark"
      style={{
        fontFamily: "Centaur",
        padding: "30px",
        margin: "auto",
        width: "85%",
        border: "2px solid #3a7ca5", // Warm blue border
        borderRadius: "10px",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)", // Subtle shadow
        backgroundColor: "#f0f4f8", // Light blue-gray background
      }}
      ref={ref}
    >
      <style>{getPageMargins()}</style>

      <div
        style={{
          fontSize: "2em",
          marginBottom: "25px",
          textAlign: "center",
          fontWeight: "bold",
          color: "#2e5977", // Warm, dark blue title color
          borderBottom: "2px solid #2e5977",
          paddingBottom: "10px",
        }}
      >
        Clinical Pharmacy Recommendation
      </div>

      <div
        style={{
          padding: "15px",
          backgroundColor: "#dbe9f1", // Soft, warm blue background
          borderRadius: "8px",
          marginBottom: "20px",
        }}
      >
        <div style={{ fontSize: "1.3em", marginBottom: "10px" }}>
          <strong style={{ color: "#c88b4a" }}>Patient Name:</strong>{" "}
          {patient.firstName} {patient.middleName} {patient.lastName}{" "}
          {patient.fourthName}
        </div>
        <div style={{ fontSize: "1.3em" }}>
          <strong style={{ color: "#c88b4a" }}>Review Date:</strong>{" "}
          {review.reviewDate && review.reviewDate.substring(0, 10)}
        </div>
      </div>

      <div
        style={{
          fontSize: "1.5em",
          marginBottom: "15px",
          fontWeight: "bold",
          color: "#3a7ca5", // Accent blue for section titles
        }}
      >
        Recommendations:
      </div>

      {review.drugErrs
        .filter((e) => e.errType !== "659edafa7198ed5cc6af99bb")
        .map((e, i) => (
          <div key={e._id} style={{ marginBottom: "20px" }}>
            <strong style={{ fontSize: "1.2em", color: "#2e5977" }}>
              {" "}
              {/* Darker blue for drug label */}
              {i + 1}. {e.errDrugLabel}
            </strong>
            <div
              style={{ fontSize: "1em", color: "#3a7ca5", fontStyle: "italic" }} // Accent blue for error type
            >
              {e.errTypeLabel}
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: e.errNote }}
              style={{
                padding: "12px",
                border: "1px solid #ddd",
                borderRadius: "6px",
                backgroundColor: "#f7f9fb", // Softest blue for error note background
                marginTop: "8px",
                fontSize: "1em",
                lineHeight: "1.6",
              }}
            />
          </div>
        ))}

      <div
        style={{
          fontSize: "1.2em",
          marginTop: "30px",
          borderTop: "2px solid #2e5977", // Darker blue divider at the bottom
          paddingTop: "15px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <strong>Edited by:</strong> {review.userName}
          </div>
          <div style={{ paddingRight: "20px" }}>
            <strong>Physician Signature</strong>
          </div>
        </div>
      </div>
    </div>
  );
});

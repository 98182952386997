import React, { lazy, Suspense } from "react";

const AboutComponent = lazy(() => import("../components/About_Laz"));

const renderLoader = () => <p>Loading</p>;

const About = () => {
  return (
    <>
      <Suspense fallback={renderLoader()}>
        <AboutComponent />
      </Suspense>
    </>
  );
};

export default About;

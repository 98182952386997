import React, { useEffect, useRef, useState } from "react";
import { differenceInDays, format } from "date-fns";
import {
  Accordion,
  Card,
  Button,
  Row,
  Col,
  Form,
  ButtonToolbar,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createReview,
  deleteReview,
  updateRevHead,
} from "../actions/reviewActions";
import { ComponentToPrint } from "../components/RevUnitPrint";
import ReactToPrint from "react-to-print";
import TextEditor from "./TextEditor";
import Skeleton from "react-loading-skeleton";
import RevError from "./RevError";
import { notify } from "./notify";

const ReviewAccordion = ({
  review,
  admission,
  labeledReview,
  expandAllRev,
}) => {
  const [showHeadEdit, setShowHeadEdit] = useState(false);
  const [reviewId, setReviewId] = useState();
  const [clinicalNote, setClinicalNote] = useState();
  const [reviewDate, setReviewDate] = useState();
  const [drugErrs, setDrugErrs] = useState();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const teamDetails = useSelector((state) => state.teamDetails);
  const { team } = teamDetails;

  const patientDetails = useSelector((state) => state.patientDetails);
  const { patient } = patientDetails;

  const revHeadUpdateStore = useSelector((state) => state.revHeadUpdateStore);
  const { reqRevId, updatedHeadId } = revHeadUpdateStore;

  const componentRef = useRef();
  const dispatch = useDispatch();

  const duplicateReview = () => {
    if (window.confirm("Are you sure you want to duplicate?")) {
      dispatch(
        createReview({
          patientId: admission.patient,
          admissionId: admission,
          userId: userInfo._id,
          teamId: team._id,
          reviewDate: new Date(),
          clinicalNote: review.clinicalNote,
          drugErrs: review.drugErrs,
        })
      );
    }
  };

  const deleteHandler = () => {
    if (window.confirm("You are going to delete this review, are you?")) {
      dispatch(deleteReview(reviewId));
    }
  };

  const newErr = () => {
    setDrugErrs([
      ...drugErrs,
      {
        idx: Math.random(),
        errDrug: "",
        errType: "",
        errNote: "",
        errRes: "",
        errSeverity: "",
      },
    ]);
  };

  const submitHandler = () => {
    dispatch(
      updateRevHead({
        reviewId: review._id,
        reviewDate: reviewDate,
        clinicalNote: clinicalNote,
      })
    );
  };

  const handleRevDate = (e) => {
    setReviewDate(e);
    review.reviewDate = e;
  };

  useEffect(() => {
    setReviewId(review._id);
    setReviewDate(review.reviewDate && review.reviewDate.substring(0, 10));
    setClinicalNote(review.clinicalNote);
    setDrugErrs(review.drugErrs);

    if (updatedHeadId === review._id) {
      setShowHeadEdit(false);
      notify({
        title: `Edit Saved`,
        message: `Your Review Updated Successfully👏👏`,
        type: "success",
        duration: 2500,
      });
    }
  }, [updatedHeadId]);
  return (
    <div>
      <div style={{ display: "none" }}>
        <ComponentToPrint
          ref={componentRef}
          review={review}
          patient={patient}
          team={team}
        />
      </div>

      {showHeadEdit ? (
        <>
          {reviewDate ? (
            <Form.Group>
              <Form.Label>
                <h2>Review Date</h2>
              </Form.Label>

              <Form.Control
                type="date"
                value={reviewDate}
                onChange={(e) => handleRevDate(e.target.value)}
              ></Form.Control>
            </Form.Group>
          ) : (
            <Skeleton />
          )}
        </>
      ) : (
        <Accordion.Toggle
          style={{
            backgroundColor:
              review._id && review._id.toString() === labeledReview
                ? "#14A44D"
                : "#00abff",
          }}
          id={review._id}
          eventKey={expandAllRev ? expandAllRev : review._id}
          as={Card.Header}
          variant="link"
        >
          <div className="row text-white">
            <div className="col col-sm-5">
              {review.reviewDate &&
                admission &&
                ` D${differenceInDays(
                  new Date(review.reviewDate),
                  new Date(admission.admissionDate)
                )}: ${format(new Date(review.reviewDate), "eeee")}
        ${review.reviewDate.substring(0, 10)}`}
            </div>
            <div className="col col-sm-5">
              <div className="row justify-content-end pr-5">
                <span>Edited by: {review.userName}</span>
              </div>
            </div>
            <div className="col col-sm-1">
              {" "}
              <i className="fas fa-angle-double-down"></i>
            </div>
          </div>
        </Accordion.Toggle>
      )}

      <Accordion.Collapse eventKey={expandAllRev ? expandAllRev : review._id}>
        <div className="container">
          {showHeadEdit ? (
            <>
              <Form.Group>
                <Form.Label>
                  <h2>Clinical Notes</h2>
                </Form.Label>
                <div className="text-dark">
                  {" "}
                  <TextEditor text={clinicalNote} setText={setClinicalNote} />
                </div>
              </Form.Group>
              <div
                className="btn btn-outline-success"
                onClick={() => submitHandler()}
              >
                Update
              </div>
            </>
          ) : (
            <div>
              {" "}
              Clinical Notes:{" "}
              <div
                className="text-primary"
                dangerouslySetInnerHTML={{
                  __html: `${clinicalNote}`,
                }}
              />
              {review.userEmail === userInfo.email && (
                <Row className="justify-content-end">
                  <Col className="col-sm-6 my-1">
                    <Row className="justify-content-end mr-2">
                      <Button onClick={() => setShowHeadEdit(true)}>
                        Edit
                      </Button>
                    </Row>
                  </Col>
                </Row>
              )}
            </div>
          )}
          <hr style={{ backgroundColor: "blue" }} />
          <h4 className="lead row justify-content-center">
            MEDICATIONS{" "}
            <span className="ml-2 badge bg-light badge-pill">
              {drugErrs && drugErrs.length}
            </span>{" "}
          </h4>
          <hr style={{ backgroundColor: "blue" }} />

          {drugErrs &&
            drugErrs.map((drugErr) => (
              <RevError
                key={drugErr._id}
                drugErr={drugErr}
                review={review}
                setDrugErrs={setDrugErrs}
                drugErrs={drugErrs}
              />
            ))}

          {review.userEmail === userInfo.email && (
            <Row className="justify-content-center">
              <Col className="col-sm-6 my-1">
                <Row className="justify-content-center mr-2 my-3">
                  <Button onClick={() => newErr()}>ADD MEDICATION</Button>
                </Row>
              </Col>
            </Row>
          )}

          <ButtonToolbar className="row d-flex justify-content-around">
            <Col className="col-sm-2 m-1">
              <ReactToPrint
                trigger={() => <Button className="col-sm-4 ml-1">Print</Button>}
                content={() => componentRef.current}
                // documentTitle={`${review.use}`}
              />
            </Col>
            {team.members.find((m) => m.user === userInfo.email) &&
              team.members.find((m) => m.user === userInfo.email).canEdit && (
                <Col
                  onClick={() => duplicateReview()}
                  className="col-sm-2 btn btn-outline-info m-1"
                >
                  Dup
                </Col>
              )}

            {userInfo && userInfo._id === review.userId && (
              <>
                <Col
                  onClick={() => deleteHandler()}
                  className="col-sm-2 btn btn-outline-danger m-1"
                >
                  Del
                </Col>
              </>
            )}
            <Col
              className="col-sm-2 m-1"
              onClick={() =>
                window.open(
                  `https://wa.me/+2${review.userPhone}?text=I'm inquiring about your review at https://www.clinicbook.org/admissions/${admission._id}?review=${review._id}`
                )
              }
            >
              <i
                className="fab fa-whatsapp"
                style={{
                  fontFamily: "Font Awesome 5 Brands",
                  fontSize: "36px",
                  color: "green",
                }}
                aria-hidden="true"
              ></i>
            </Col>
          </ButtonToolbar>
          <hr style={{ backgroundColor: "blue" }} />
        </div>
      </Accordion.Collapse>
    </div>
  );
};

export default ReviewAccordion;

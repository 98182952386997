import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { tagListReducer } from "./reducers/tagReducers";
import {
  userLoginReducer,
  userDeleteReducer,
  userRegisterReducer,
  updateUserDetailsReducer,
  listSponsorsReducer,
  userDetailsReducer,
  recommendUserReducer,
  userMetricsReducer,
} from "./reducers/userReducers";
import {
  patientListReducer,
  newPatientReducer,
  patientDetailsReducer,
  patientUpdateReducer,
  PatientDeletedReducer,
  inpatientListReducer,
} from "./reducers/patientReducers";

import {
  drugsReducer,
  errTempsReducer,
  listDrugRootsReducer,
  newDrugReducer,
  rootByTradeIdReducer,
  updateRootReducer,
} from "./reducers/drugReducers";
import {
  newTeamReducer,
  teamDetailsReducer,
  teamUpdateReducer,
  userTeamsReducer,
} from "./reducers/teamReducers";
import {
  admissionCreateReducer,
  admissionDetailsReducer,
  admissionUpdateReducer,
  admissionListReducer,
  admissionDeleteReducer,
  admissionRecurrentReducer,
  admMedUpdateReducer,
  newAdmMedReducer,
  deletedAdmMedReducer,
  admMedSortReducer,
} from "./reducers/admissionReducers";
import {
  admissionReviewReducer,
  errRatingReducer,
  errTypeDistributionReducer,
  revHeadUpdateReducer,
  reviewCreateReducer,
  reviewDeleteReducer,
  reviewListPerDayReducer,
  reviewSearchProgressReducer,
  reviewSearchReducer,
  reviewsPerTagReducer,
  reviewUpdateReducer,
  revMedAddReducer,
  revMedDashboardReducer,
  revMedDelReducer,
  revMedUpdateReducer,
} from "./reducers/reviewReducers";
import { errTypeListReducer } from "./reducers/errTypeReducers";
import {
  physicNoteCreateReducer,
  physicNoteDeleteReducer,
  physicNoteListReducer,
  physicNoteUpdateReducer,
} from "./reducers/physicNoteReducers";
import {
  userErrTempAddReducer,
  userErrTempDelReducer,
  userErrTempReducer,
} from "./reducers/userErrTempReducer";
import { recaptchaConstantReducer } from "./reducers/recaptchaReducer";

const reducer = combineReducers({
  drugRootsStore: listDrugRootsReducer,
  tradeLabelsStore: drugsReducer,
  errTypes: errTypeListReducer,
  errTempsStore: errTempsReducer,
  newDrugStore: newDrugReducer,
  rootByTradeIdStore: rootByTradeIdReducer,
  updateRootStore: updateRootReducer,
  admMedSortStore: admMedSortReducer,

  registeredUser: userRegisterReducer,
  updatedUserDetails: updateUserDetailsReducer,

  patientListStore: patientListReducer,
  patientDetails: patientDetailsReducer,
  newPatientStore: newPatientReducer,
  patientUpdateStore: patientUpdateReducer,
  patientDeleteStore: PatientDeletedReducer,
  inpatientListStore: inpatientListReducer,
  tagListStore: tagListReducer,

  physicNoteCreateStore: physicNoteCreateReducer,
  physicNoteListStore: physicNoteListReducer,
  physicNoteUpdateStore: physicNoteUpdateReducer,
  physicNoteDeleteStore: physicNoteDeleteReducer,

  admListStore: admissionListReducer,
  admissionDetails: admissionDetailsReducer,
  admNewStore: admissionCreateReducer,
  admUpdateStore: admissionUpdateReducer,
  admDeleteStore: admissionDeleteReducer,
  admRecurrentStore: admissionRecurrentReducer,

  admMedUpdateStore: admMedUpdateReducer,
  admMedNewStore: newAdmMedReducer,
  admMedDelStore: deletedAdmMedReducer,

  userDetails: userDetailsReducer,
  userLogin: userLoginReducer,
  userMetricsStore: userMetricsReducer,
  userDelete: userDeleteReducer,
  sponsorsStore: listSponsorsReducer,
  recommendUserStore: recommendUserReducer,

  teamAddStore: newTeamReducer,
  teamDetails: teamDetailsReducer,
  teamUpdateStore: teamUpdateReducer,
  teamsOfUserStore: userTeamsReducer,

  reviewAddStore: reviewCreateReducer,
  reviewSearchStore: reviewSearchReducer,
  reviewSearchProgressStore: reviewSearchProgressReducer,

  revHeadUpdateStore: revHeadUpdateReducer,
  revMedUpdateStore: revMedUpdateReducer,
  revMedAddStore: revMedAddReducer,
  revMedDelStore: revMedDelReducer,

  reviewDeleteStore: reviewDeleteReducer,
  reviewsByErrTypeStore: errTypeDistributionReducer,
  reviewsByAdmissionStore: admissionReviewReducer,
  reviewsPerDayStore: reviewListPerDayReducer,
  reviewErrRatingStore: errRatingReducer,
  reviewsPerTagStore: reviewsPerTagReducer,

  revMedDashboardStore: revMedDashboardReducer,

  userErrTempStore: userErrTempReducer,
  userErrTempDelStore: userErrTempDelReducer,
  userErrTempAddStore: userErrTempAddReducer,

  recaptchaConstantStore: recaptchaConstantReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
};

const middleware = [thunk];

const store =
  process.env.NODE_ENV === "production"
    ? createStore(reducer, initialState, applyMiddleware(...middleware))
    : createStore(
        reducer,
        initialState,
        composeWithDevTools(applyMiddleware(...middleware))
      );

export default store;

import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const BreadcrumbComponent = ({ titles }) => {
  const navigate = useNavigate()
  return (
    <Breadcrumb>
      {titles.map((t) => (
        <Breadcrumb.Item
          key={t.idx}
          style={{ fontWeight: t.active ? 'bold' : 'light' }}
          onClick={() => {
            navigate(`${t.path}`)
          }}>
          {t.label}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

export default BreadcrumbComponent

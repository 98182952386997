import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_DELETE_REQUEST,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  USER_DETAILS_RESET,
  USER_UPDATE_DETAILS_REQUEST,
  USER_UPDATE_DETAILS_SUCCESS,
  USER_UPDATE_DETAILS_FAIL,
  USER_UPDATE_DETAILS_RESET,
  USER_REGISTER_RESET,
  SPONSORS_REQUEST,
  SPONSORS_SUCCESS,
  SPONSORS_FAIL,
  SPONSORS_RESET,
  RECOMMEND_USER_REQUEST,
  RECOMMEND_USER_SUCCESS,
  RECOMMEND_USER_FAIL,
  RECOMMEND_USER_RESET,
  USER_METRICS_REQUEST,
  USER_METRICS_SUCCESS,
  USER_METRICS_FAIL,
  USER_METRICS_RESET,
} from "../constants/userConstants";

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true };
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const userDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DELETE_REQUEST:
      return { loading: true };
    case USER_DELETE_SUCCESS:
      return { loading: false, success: true };
    case USER_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const listSponsorsReducer = (state = {}, action) => {
  switch (action.type) {
    case SPONSORS_REQUEST:
      return { loading: true };
    case SPONSORS_SUCCESS:
      return { loading: false, sponsors: action.payload };
    case SPONSORS_FAIL:
      return { loading: false, error: action.payload };
    case SPONSORS_RESET:
      return {};
    default:
      return state;
  }
};

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return { loading: true };
    case USER_REGISTER_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case USER_REGISTER_FAIL:
      return { loading: false, error: action.payload };
    case USER_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};

export const userDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return { loading: true };
    case USER_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

export const userMetricsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_METRICS_REQUEST:
      return { loading: true };
    case USER_METRICS_SUCCESS:
      return { loading: false, metrics: action.payload };
    case USER_METRICS_FAIL:
      return { loading: false, error: action.payload };
    case USER_METRICS_RESET:
      return {};
    default:
      return state;
  }
};

export const recommendUserReducer = (state = {}, action) => {
  switch (action.type) {
    case RECOMMEND_USER_REQUEST:
      return { loading: true };
    case RECOMMEND_USER_SUCCESS:
      return { loading: false, status: action.payload };
    case RECOMMEND_USER_FAIL:
      return { loading: false, error: action.payload };
    case RECOMMEND_USER_RESET:
      return {};
    default:
      return state;
  }
};

export const updateUserDetailsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATE_DETAILS_REQUEST:
      return { loading: true };
    case USER_UPDATE_DETAILS_SUCCESS:
      return { loading: false, user: action.payload };
    case USER_UPDATE_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case USER_UPDATE_DETAILS_RESET:
      return {};
    default:
      return state;
  }
};

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Row, Col, FormGroup, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import PasswordChecker from "../components/PasswordChecker";
import { registerUser } from "../actions/userActions";
import ReCAPTCHA from "react-google-recaptcha";
import { callRecaptcha } from "../actions/recaptchaActions";

const RegisterScreen = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();
  const captchaRef = useRef(null);

  const registeredUser = useSelector((state) => state.registeredUser);
  const { loading, error } = registeredUser;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const recaptchaConstantStore = useSelector(
    (state) => state.recaptchaConstantStore
  );
  const { siteKey } = recaptchaConstantStore;

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo) {
      navigate("/teamlist");
    }
    if (!siteKey) {
      dispatch(callRecaptcha());
    }
  }, [userInfo, siteKey]);

  const submitHandler = (e) => {
    e.preventDefault();
    const token = captchaRef.current.getValue();
    setMessage("");
    if (password && password === confirmPassword) {
      dispatch(
        registerUser({
          firstName,
          lastName,
          email: email.toLowerCase(),
          phone,
          password,
          token,
        })
      );
    }

    if (!password) {
      setMessage("Password is required");
    }
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    }
  };

  return (
    <FormContainer>
      <h1>Sign Up</h1>
      {message && <Message variant="danger">{message}</Message>}
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler} className="form-row">
        <Form.Group controlId="firstName" className="col-sm-6">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            type="name"
            placeholder="Enter name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="lastName" className="col-sm-6">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            type="name"
            placeholder="Enter name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="email" className="col-sm-12">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="phone" className="col-sm-12">
          <Form.Label>Whatsapp Phone </Form.Label>
          <Form.Control
            placeholder="Enter Phone (Whatsapp)"
            type="phone"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password" className="col-sm-12">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Enter password"
            value={password}
            suggested="current-password"
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <PasswordChecker password={password} />

        <Form.Group controlId="confirmPassword" className="col-sm-12">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            suggested="current-password"
            placeholder="Confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <FormGroup className="col-sm-12">
          {siteKey ? (
            <ReCAPTCHA sitekey={siteKey} ref={captchaRef} />
          ) : (
            "loading"
          )}
        </FormGroup>

        <FormGroup>
          <Button type="submit" variant="primary">
            Create Account
          </Button>
        </FormGroup>
      </Form>

      <Row className="py-3">
        <Col>
          Have an Account?{" "}
          <span
            className="link text-success"
            onClick={() => {
              navigate("/");
            }}
          >
            Login
          </span>
        </Col>
      </Row>
    </FormContainer>
  );
};

export default RegisterScreen;

import axios from "axios";
import {
  PATIENT_DETAILS_REQUEST,
  PATIENT_DETAILS_SUCCESS,
  PATIENT_DETAILS_FAIL,
  PATIENT_LIST_REQUEST,
  PATIENT_LIST_FAIL,
  PATIENT_LIST_SUCCESS,
  PATIENT_REGISTER_REQUEST,
  PATIENT_REGISTER_SUCCESS,
  PATIENT_REGISTER_FAIL,
  PATIENT_UPDATE_REQUEST,
  PATIENT_UPDATE_SUCCESS,
  PATIENT_UPDATE_FAIL,
  PATIENT_DELETE_REQUEST,
  PATIENT_DELETE_SUCCESS,
  PATIENT_DELETE_FAIL,
  INPATIENT_LIST_REQUEST,
  INPATIENT_LIST_SUCCESS,
  INPATIENT_LIST_FAIL,
} from "../constants/patientConstants";
import { logout } from "./userActions";

export const registerPatient = (patient) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PATIENT_REGISTER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `/api/patients`,
      {
        patient,
      },
      config
    );
    dispatch({
      type: PATIENT_REGISTER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PATIENT_REGISTER_FAIL,
      payload: message,
    });
  }
};

export const deletePatient = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PATIENT_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.delete(
      `/api/patients/${id}`,

      config
    );
    dispatch({
      type: PATIENT_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PATIENT_DELETE_FAIL,
      payload: message,
    });
  }
};

export const listPatient = (filters) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PATIENT_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
        team: filters.team,
      },
    };
    const { data } = await axios.get(
      `/api/patients/team/${filters.team}?firstName=${
        filters.firstName || ""
      }&middleName=${filters.middleName || ""}&lastName=${
        filters.lastName || ""
      }&fourthName=${filters.fourthName || ""}&birthdate=${
        filters.birthdate || ""
      }&pageNumber=${filters.pageNumber}`,
      config
    );
    dispatch({
      type: PATIENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PATIENT_LIST_FAIL,
      payload: message,
    });
  }
};

export const listInpatient = (teamId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: INPATIENT_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
        team: teamId,
      },
    };
    const { data } = await axios.get(
      `/api/patients/inpatients/${teamId}`,
      config
    );
    dispatch({
      type: INPATIENT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: INPATIENT_LIST_FAIL,
      payload: message,
    });
  }
};

export const getPatientDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PATIENT_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/patients/${id}`, config);

    dispatch({
      type: PATIENT_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: PATIENT_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const updatePatient =
  (
    patientId,
    firstName,
    middleName,
    lastName,
    fourthName,
    medicalRecordNumber,
    birthdate
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: PATIENT_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `/api/patients/${patientId}/edit`,
        {
          firstName,
          middleName,
          lastName,
          birthdate,
          medicalRecordNumber,
          fourthName,
        },
        config
      );
      dispatch({
        type: PATIENT_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: PATIENT_UPDATE_FAIL,
        payload: message,
      });
    }
  };

import { USER_LOGOUT } from "../constants/userConstants";
import {
  USER_ERR_TEMP_ADD_FAIL,
  USER_ERR_TEMP_ADD_REQUEST,
  USER_ERR_TEMP_ADD_RESET,
  USER_ERR_TEMP_ADD_SUCCESS,
  USER_ERR_TEMP_DELETE_FAIL,
  USER_ERR_TEMP_DELETE_REQUEST,
  USER_ERR_TEMP_DELETE_RESET,
  USER_ERR_TEMP_DELETE_SUCCESS,
  USER_ERR_TEMP_FAIL,
  USER_ERR_TEMP_REQUEST,
  USER_ERR_TEMP_RESET,
  USER_ERR_TEMP_SUCCESS,
} from "../constants/userErrTempConstants";

export const userErrTempReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ERR_TEMP_REQUEST:
      return { loading: true };
    case USER_ERR_TEMP_SUCCESS:
      return { loading: false, temps: action.payload };
    case USER_ERR_TEMP_FAIL:
      return { loading: false, error: action.payload };
    case USER_ERR_TEMP_RESET:
      return {};
    default:
      return state;
  }
};

export const userErrTempDelReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ERR_TEMP_DELETE_REQUEST:
      return { loading: true };
    case USER_ERR_TEMP_DELETE_SUCCESS:
      return { loading: false, message: action.payload };
    case USER_ERR_TEMP_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case USER_ERR_TEMP_DELETE_RESET:
      return {};
    default:
      return state;
  }
};

export const userErrTempAddReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ERR_TEMP_ADD_REQUEST:
      return { loading: true };
    case USER_ERR_TEMP_ADD_SUCCESS:
      return { loading: false, newTemp: action.payload };
    case USER_ERR_TEMP_ADD_FAIL:
      return { loading: false, error: action.payload };
    case USER_ERR_TEMP_ADD_RESET:
      return {};
    default:
      return state;
  }
};

import React from "react";
import { Chart } from "react-google-charts";

const ChartComponent = ({ data, y_axis, chartTitle }) => {
  const options = {
    title: chartTitle,
    chartArea: { width: "30%" },
    hAxis: {
      minValue: 0,
    },
    vAxis: {
      title: y_axis,
    },
  };
  return (
    <div>
      {data && (
        <Chart
          chartType="BarChart"
          width="100%"
          height="600px"
          data={data}
          options={options}
        />
      )}
    </div>
  );
};

export default ChartComponent;

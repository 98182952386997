import React, { useEffect, useState } from "react";
import { Form, Button, Modal, ModalBody } from "react-bootstrap";
import { useDispatch } from "react-redux";
import TextEditor from "./TextEditor";
import Message from "./Message";
import { deleteAdmission, updateAdmission } from "../actions/admissionActions";
import dischargeStatusList from "../data/dischargeStatusList";

const AdmissionUpdateModal = ({ selectedAdmission, setSelectedAdmission }) => {
  const [admissionId, setAdmissionId] = useState("");
  const [admissionDate, setAdmissionDate] = useState("");
  const [admissionDetails, setAdmissionDetails] = useState("");
  const [dischargeDate, setDischargeDate] = useState("");
  const [dischargeStatus, setDischargeStatus] = useState("");
  const [dischargeNote, setDischargeNote] = useState("");
  const [reviewsCount, setReviewsCount] = useState(true);

  const [message, setMessage] = useState("");

  const [showModaL, setShowModaL] = useState(false);

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateAdmission({
        _id: admissionId,
        admissionDate,
        dischargeDate,
        dischargeStatus,
        dischargeNote,
        admissionDetails,
      })
    );
    setSelectedAdmission();
  };

  const handleDelete = (id) => {
    dispatch(deleteAdmission(id));
    setSelectedAdmission();
  };

  useEffect(() => {
    if (selectedAdmission) {
      setAdmissionId(selectedAdmission._id);
      setAdmissionDate(selectedAdmission.admissionDate.substring(0, 10));
      setAdmissionDetails(selectedAdmission.admissionDetails);
      setReviewsCount(selectedAdmission.reviewsCount);
      if (selectedAdmission.dischargeDate) {
        setDischargeDate(selectedAdmission.dischargeDate.substring(0, 10));
      }
      if (selectedAdmission.dischargeStatus) {
        setDischargeStatus(selectedAdmission.dischargeStatus);
      }
      if (selectedAdmission.dischargeNote) {
        setDischargeNote(selectedAdmission.dischargeNote);
      }
      setShowModaL(true);
    } else {
      setAdmissionId("");
      setAdmissionDate("");
      setAdmissionDetails("");
      setDischargeDate("");
      setDischargeNote("");
      setShowModaL(false);
    }
  }, [selectedAdmission, showModaL]);

  return (
    <>
      {message && <Message variant="danger">{message}</Message>}

      <Modal
        show={showModaL}
        size="lg"
        onHide={() => setSelectedAdmission()}
        backdrop="static"
        keyboard={false}
      >
        <ModalBody>
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="admissionDate">
              <Form.Label>
                <h2>Admission Date</h2>
              </Form.Label>
              <Form.Control
                type="date"
                value={admissionDate}
                onChange={(e) => setAdmissionDate(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <div className="text-dark">
              <Form.Label>
                <h2>Clinical Details</h2>
              </Form.Label>

              <TextEditor
                text={admissionDetails}
                setText={setAdmissionDetails}
              />
            </div>

            <Form.Group controlId="dischargeDate" className="py-3">
              <Form.Label>
                <h2>Discharge Date</h2>
              </Form.Label>
              <Form.Control
                type="date"
                value={dischargeDate}
                onChange={(e) => setDischargeDate(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="dischargeStatus">
              <Form.Label>Discharge Status</Form.Label>
              <select
                className="custom-select"
                type="name"
                value={dischargeStatus}
                onChange={(e) => setDischargeStatus(e.target.value)}
              >
                {dischargeStatusList.map((s) => (
                  <option key={s._id} value={s._id}>
                    {s.label}
                  </option>
                ))}
              </select>
            </Form.Group>

            <div className="text-dark">
              <Form.Label>
                <h2>Discharge Note</h2>
              </Form.Label>
              <TextEditor text={dischargeNote} setText={setDischargeNote} />
            </div>

            <Form.Group className="py-3">
              {reviewsCount === 0 && (
                <Button
                  className="btn btn-danger"
                  onClick={() => handleDelete(admissionId)}
                >
                  Delete
                </Button>
              )}
              <Button className="btn btn-info float-right" type="submit">
                Update
              </Button>
              <Button
                className="btn btn-info float-right mx-2"
                onClick={() => setSelectedAdmission()}
              >
                Close
              </Button>
            </Form.Group>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AdmissionUpdateModal;

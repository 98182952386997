import React, { useEffect, useState } from "react";
import { Card, CardGroup, ListGroup, ListGroupItem } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { getTeamDetails } from "../actions/teamActions";
import FormContainer from "../components/FormContainer";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { notify } from "../components/notify";
import Skeleton from "react-loading-skeleton";
import BreadcrumbComponent from "../components/BreadcrumbComponent";

function TeamProfileScreen() {
  const params = useParams();
  const teamId = params.id;

  const [message, setMessage] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const teamDetails = useSelector((state) => state.teamDetails);
  const { loading, team, error } = teamDetails;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (!userInfo) {
      navigate(`/?returnFrom=${location.pathname}`);
    } else {
      if (error) {
        notify({
          title: "Unexpected Error",
          message: `${error}`,
          type: "danger",
          duration: 2000,
        });
      } else {
        if (!team || team._id !== teamId) {
          dispatch(getTeamDetails(teamId));
        }
      }
    }
  }, [userInfo, teamId, error]);

  return (
    <FormContainer>
      <div>
        {!team || loading ? (
          <>
            <Skeleton height="50px" className="mb-5" />
            <Loader />
          </>
        ) : error ? (
          <Message
            children={message}
            variant="danger"
            setMessgae={setMessage}
          />
        ) : (
          <>
            <BreadcrumbComponent
              titles={[
                { idx: "1", label: "Home", path: `/`, active: false },
                {
                  idx: "2",
                  label: `${team.name}`,
                  path: `/team/${team._id}`,
                  active: true,
                },
              ]}
            />

            <div
              dangerouslySetInnerHTML={{
                __html: `${team && team.about}`,
              }}
            />

            <h1>Members</h1>
            <ListGroup>
              {team.members.map((m, order = 1) => (
                <ListGroupItem
                  key={order + 1}
                  variant="primary"
                  className="d-flex justify-content-between align-items-center m-2 "
                >
                  <p className="mb-0">
                    {m.isPhysic ? (
                      <>
                        <i className="fa fa-stethoscope me-1"></i>
                        {` ${m.firstName} ${m.lastName}`}
                      </>
                    ) : (
                      <>
                        <i className="fas fa-shield-alt me-1"></i>
                        {` ${m.firstName} ${m.lastName}`}
                      </>
                    )}
                    <br />
                  </p>
                </ListGroupItem>
              ))}
            </ListGroup>

            <ListGroup style={{ color: "#00abff" }} className="mt-3">
              <ListGroupItem
                onClick={() => {
                  navigate(`/patientlist/${teamId}`);
                }}
                className="mb-2 d-flex justify-content-between align-items-center border-dark"
              >
                <h1>Patients</h1>
                <i className="fas fa-external-link-alt"></i>
              </ListGroupItem>
              <ListGroupItem
                onClick={() => {
                  navigate(`/reviewSearch/${teamId}`);
                }}
                className="mb-2 d-flex justify-content-between align-items-center border-dark "
              >
                <h1>Search and Print</h1>
                <i className="fas fa-external-link-alt"></i>
              </ListGroupItem>

              {team && userInfo && team.admin === userInfo.email && (
                <ListGroupItem
                  onClick={() => {
                    navigate(`/teamlist/${teamId}`);
                  }}
                  className="mb-2 d-flex justify-content-between align-items-center border-dark"
                >
                  <h1>Admin</h1>
                  <i className="fas fa-external-link-alt"></i>
                </ListGroupItem>
              )}
            </ListGroup>
          </>
        )}
      </div>
    </FormContainer>
  );
}

export default TeamProfileScreen;

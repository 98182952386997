import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import { getUserDetails, recommUser } from "../actions/userActions";
import FormContainer from "../components/FormContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Counter from "../components/Counter";
import TextEditor from "../components/TextEditor";
import Meta from "../components/Meta";
import UserMetrics from "../components/UserMetrics";
import Skeleton from "react-loading-skeleton";

const UserPublicScreen = () => {
  const params = useParams();
  let userId = params.id;

  const [recommendation, setRecommendation] = useState("");
  const [message, setMessage] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, user, error } = userDetails;

  const recommendUserStore = useSelector((state) => state.recommendUserStore);
  const { status } = recommendUserStore;

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      recommUser({
        user: userId,
        recommender: userInfo._id,
        recommendation,
      })
    );
  };

  useEffect(() => {
    if (!user || user._id !== userId) {
      dispatch(getUserDetails(userId));
    }
  }, [user, userId, dispatch, userInfo]);

  return (
    <>
      <Meta
        title={user && `${user.firstName} ${user.lastName}`}
        description={user && user.title}
      />
      <FormContainer>
        {!user ? (
          <Skeleton height="50px" className="mb-5" />
        ) : (
          <BreadcrumbComponent
            titles={[
              { idx: "1", label: "Home", path: `/`, active: false },
              { idx: "2", label: "Profile Screen", path: ``, active: false },
              {
                idx: "3",
                label: `${user.firstName} ${user.lastName}`,
                path: ``,
                active: true,
              },
            ]}
          />
        )}

        {message && <Message variant="danger">{message}</Message>}

        {!user ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <h3>{`${user.firstName} ${user.lastName} | ${user.title}`}</h3>
            <Row
              className="ml-3 text-primary"
              dangerouslySetInnerHTML={{
                __html: `${user.about}`,
              }}
            />
            <UserMetrics userId={userId} />

            {/* <h3>Recommendations</h3>
            {user.recommendations && user.recommendations.length === 0 ? (
              <Message children={`No recommendetions yet`} variant="success" />
            ) : (
              user.recommendations.map((r) => (
                <Card key={r._id} className="m-3 border-primary">
                  <Card.Header className="text-primary">
                    {`${r.recommenderName} | ${r.recommenderTitle}`}
                  </Card.Header>
                  <Card.Body
                    className="text-primary"
                    dangerouslySetInnerHTML={{
                      __html: `${r.recommendation}`,
                    }}
                  />
                </Card>
              ))
            )} */}

            {!userInfo ? (
              <div className="col-12 d-flex justify-content-end">
                {" "}
                <div
                  className="btn btn-outline-primary"
                  onClick={() => navigate(`/?returnFrom=${location.pathname}`)}
                >
                  Log in to add recommendation
                </div>
              </div>
            ) : userInfo._id.toString() === userId.toString() ? (
              <div className="col-12 d-flex justify-content-end">
                <Button
                  class="btn mx-auto"
                  onClick={() => navigate("/profile")}
                >
                  Edit your profile
                </Button>
              </div>
            ) : !user.recommendations.find(
                (r) => r.recommender.toString() === userInfo._id.toString()
              ) ? (
              <Form onSubmit={submitHandler}>
                <Form.Group>
                  <Form.Label>
                    <strong>Do you recommend this user?</strong>
                  </Form.Label>
                  <TextEditor
                    text={recommendation}
                    setText={setRecommendation}
                  />
                </Form.Group>
                {status && (
                  <Message children={status.message} variant="success" />
                )}
                <div className="col-12 d-flex justify-content-end">
                  <Button class="btn mx-auto" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            ) : (
              <>You had recommend this user before, see above.</>
            )}
          </>
        )}
      </FormContainer>
    </>
  );
};

export default UserPublicScreen;

import React, { useState, useEffect } from "react";
import { Card, Accordion, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listAdmissions } from "../actions/admissionActions";
import AdmissionForm from "./AdmissionForm";
import { useNavigate } from "react-router-dom";
import { getTeamDetails } from "../actions/teamActions";
import {
  ADMISSION_CREATE_RESET,
  ADMISSION_DELETE_RESET,
  ADMISSION_UPDATE_RESET,
} from "../constants/admissionConstants";
import AdmissionUpdateModal from "./AdmissionUpdateModal";

import Message from "./Message";
import Skeleton from "react-loading-skeleton";
import dischargeStatusList from "../data/dischargeStatusList";

const AdmissionList = ({ patientId, editPermission }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedAdmission, setSelectedAdmission] = useState();
  const [admListVehicle, setAdmListVehicle] = useState();
  const [message, setMessage] = useState("");

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const patientDetails = useSelector((state) => state.patientDetails);
  const { patient } = patientDetails;

  const admListStore = useSelector((state) => state.admListStore);
  const { loading, admissions, error } = admListStore;

  const admUpdateStore = useSelector((state) => state.admUpdateStore);
  const { updatedAdmission } = admUpdateStore;

  const admNewStore = useSelector((state) => state.admNewStore);
  const { loading: loadingNewAdmission, newAdmission } = admNewStore;

  const teamDetails = useSelector((state) => state.teamDetails);
  const { team } = teamDetails;

  const admDeleteStore = useSelector((state) => state.admDeleteStore);
  const { deletedAdmission } = admDeleteStore;

  useEffect(() => {
    if (error) {
      setMessage(error);
    } else {
      if (!patient || patient._id !== patientId || !admListVehicle) {
        dispatch(listAdmissions(patientId));
      } else {
        if (!team || team._id !== patient.team) {
          dispatch(getTeamDetails(patient.team));
        }
      }
    }

    if (admissions) {
      setAdmListVehicle(admissions);
    }

    if (newAdmission) {
      dispatch({ type: ADMISSION_CREATE_RESET });
      dispatch(listAdmissions(patientId));
    }

    if (updatedAdmission) {
      dispatch({ type: ADMISSION_UPDATE_RESET });
      dispatch(listAdmissions(patientId));
    }

    if (deletedAdmission) {
      dispatch({ type: ADMISSION_DELETE_RESET });
      dispatch(listAdmissions(patientId));
    }
  }, [
    dispatch,
    patient,
    patientId,
    userInfo,
    team,
    newAdmission,
    updatedAdmission,
    admissions,
    deletedAdmission,
    error,
  ]);

  return (
    <>
      <AdmissionUpdateModal
        selectedAdmission={selectedAdmission}
        setSelectedAdmission={() => setSelectedAdmission()}
      />

      <div className="mt-5">
        {loadingNewAdmission || loading ? (
          <>
            <h1 className="row justify-content-center text-primary">
              ADMISSION LIST
            </h1>
            <Skeleton height="50px" className="mb-1" count={2} />
          </>
        ) : (
          <>
            <div className="row justify-content-center">
              <h1 className="text-primary">ADMISSION LIST</h1>
              {message && (
                <Message
                  children={message}
                  variant="danger"
                  setMessgae={setMessage}
                />
              )}
            </div>
            {admListVehicle &&
              admListVehicle.map((adm, order = 0) => (
                <div key={order + 1}>
                  <Accordion
                    key={adm._id}
                    className="py-2"
                    defaultActiveKey={admListVehicle[0]._id}
                  >
                    <Accordion.Toggle
                      eventKey={adm._id}
                      as={Card.Header}
                      variant="link"
                    >
                      <Row>
                        <Col>
                          <h6>
                            Admission Date:{" "}
                            {adm.admissionDate &&
                              adm.admissionDate.substring(0, 10)}
                          </h6>
                        </Col>
                        <Col>
                          <Row className="justify-content-end pr-3">
                            <i className="fas fa-angle-double-down"></i>
                          </Row>
                        </Col>
                      </Row>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={adm._id}>
                      <div className="container">
                        <Container>
                          <Row>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `${adm.admissionDetails}`,
                              }}
                            />
                          </Row>

                          {adm.dischargeDate && (
                            <Row>
                              <h6>
                                Discharge Date:{" "}
                                {adm.dischargeDate.substring(0, 10)}
                              </h6>{" "}
                            </Row>
                          )}

                          {adm.dischargeStatus && (
                            <Row>
                              <h6>{`Discharge Status: ${
                                dischargeStatusList.find(
                                  (s) =>
                                    s._id.toString() ===
                                    adm.dischargeStatus.toString()
                                ).label
                              }`}</h6>{" "}
                            </Row>
                          )}

                          {adm.dischargeNote && (
                            <Row>
                              <h6>Discharge Note: </h6>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: ` ${adm.dischargeNote}`,
                                }}
                              />
                            </Row>
                          )}
                          <Row>
                            <Col>
                              <div className="row">
                                {editPermission && (
                                  <div
                                    className="row btn btn-outline-primary"
                                    onClick={() => setSelectedAdmission(adm)}
                                  >
                                    Edit
                                  </div>
                                )}
                              </div>
                            </Col>
                            <Col>
                              <div className="row justify-content-end">
                                <div
                                  onClick={() => {
                                    navigate(`/admissions/${adm._id}`);
                                  }}
                                  className="row btn btn-outline-primary"
                                >
                                  See Reviews
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                </div>
              ))}
          </>
        )}

        {editPermission && <AdmissionForm patientId={patientId} />}
      </div>
    </>
  );
};

export default AdmissionList;

import axios from "axios";
import {
  PHYSIC_NOTE_CREATE_FAIL,
  PHYSIC_NOTE_CREATE_REQUEST,
  PHYSIC_NOTE_CREATE_SUCCESS,
  PHYSIC_NOTE_DELETE_FAIL,
  PHYSIC_NOTE_DELETE_REQUEST,
  PHYSIC_NOTE_DELETE_SUCCESS,
  PHYSIC_NOTE_LIST_FAIL,
  PHYSIC_NOTE_LIST_REQUEST,
  PHYSIC_NOTE_LIST_SUCCESS,
  PHYSIC_NOTE_UPDATE_REQUEST,
  PHYSIC_NOTE_UPDATE_RESET,
  PHYSIC_NOTE_UPDATE_SUCCESS,
} from "../constants/physicConstants";

export const createPhysicNote = (noteData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PHYSIC_NOTE_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `/api/physicNotes/addphysicNote`,
      { noteData },
      config
    );

    dispatch({
      type: PHYSIC_NOTE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    // if (message === "Not authorized, token failed") {
    //   dispatch(logout());
    // }
    dispatch({
      type: PHYSIC_NOTE_CREATE_FAIL,
      payload: message,
    });
  }
};

export const listAdmissionNotes = (props) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PHYSIC_NOTE_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        team: props.team,
      },
    };

    const { data } = await axios.get(
      `/api/physicNotes/listAdmNotes/${props.admissionId}`,
      config
    );
    dispatch({
      type: PHYSIC_NOTE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PHYSIC_NOTE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updatePhysicNote = (noteData) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PHYSIC_NOTE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `/api/physicNotes/updatePhysicNote/${noteData.noteId}`,
      noteData,
      config
    );

    dispatch({
      type: PHYSIC_NOTE_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    // if (message === 'Not authorized, token failed') {
    //   dispatch(logout())
    // }
    dispatch({
      type: PHYSIC_NOTE_UPDATE_RESET,
      payload: message,
    });
  }
};

export const deletePhysicNote = (noteId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PHYSIC_NOTE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.delete(
      `/api/physicNotes/deletePhysicNote/${noteId}`,
      config
    );

    dispatch({
      type: PHYSIC_NOTE_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    // if (message === 'Not authorized, token failed') {
    //   dispatch(logout())
    // }
    dispatch({
      type: PHYSIC_NOTE_DELETE_FAIL,
      payload: message,
    });
  }
};

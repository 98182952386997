import React, { useState, useEffect } from "react";
import {
  Form,
  FormGroup,
  FormLabel,
  Button,
  Row,
  ListGroup,
  ListGroupItem,
  Breadcrumb,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getTeamDetails, updateTeamDetails } from "../actions/teamActions";
import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import Message from "../components/Message";
import specialityList from "../data/speciality";
import FormCheckInput from "react-bootstrap/esm/FormCheckInput";
import { useNavigate, useParams } from "react-router-dom";
import {
  TEAM_DETAILS_RESET,
  TEAM_UPDATE_RESET,
} from "../constants/teamConstants";
import TextEditor from "../components/TextEditor";

const TeamEditScreen = () => {
  const params = useParams();
  const teamId = params.id;

  const [name, setName] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [about, setAbout] = useState("");
  const [members, setMembers] = useState([]);
  const [newMember, setNewMember] = useState({
    id: "",
    user: "",
    canRead: true,
    canEdit: true,
    disabled: false,
    isPhysic: false,
  });
  const [message, setMessage] = useState("");
  const [cloudName, setCloudName] = useState("");
  const [uploadPreset, setUploadPreset] = useState("");

  const [update, setUpdate] = useState(false);

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const teamDetails = useSelector((state) => state.teamDetails);
  const { loading: loadingDetails, team } = teamDetails;

  const teamUpdateStore = useSelector((state) => state.teamUpdateStore);
  const { loading: loadingUpdate, team: updatedTeam, error } = teamUpdateStore;

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      updateTeamDetails({
        teamId,
        name,
        specialty,
        members,
        about,
        cloudName,
        uploadPreset,
      })
    );
  };
  const handleMembers = () => {
    if (newMember.email !== "") {
      //clear the message
      setMessage("");
      const isRepeated = members.find((m) => m.email === newMember.email);

      if (isRepeated) {
        setMessage("This member already added");
      } else {
        setMembers([...members, newMember]);
      }
    } else {
      setMessage("User email is required");
    }
  };

  const setPhysic = (m) => {
    m.isPhysic = true;
    m.canEdit = false;
    setUpdate(!update);
  };

  const editChange = (m) => {
    m.isPhysic = false;
    m.canEdit = true;
    setUpdate(!update);
  };
  const deleteMember = (e) => {
    setMembers([
      ...members.filter((m) => {
        return m.user !== e.user;
      }),
    ]);
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    } else {
      if (!team || team._id !== teamId) {
        dispatch(getTeamDetails(teamId));
      } else {
        setName(team.name);
        setSpecialty(team.specialty);
        setAbout(team.about);
        setMembers(team.members);
        setCloudName(team.cloudName);
        setUploadPreset(team.uploadPreset);
      }
    }
    if (updatedTeam) {
      dispatch({ type: TEAM_DETAILS_RESET });
      dispatch({ type: TEAM_UPDATE_RESET });
    }
  }, [userInfo, teamId, team, navigate, update]);

  return (
    <>
      {error && <Message variant="danger" children={error} />}
      <div>
        {loadingDetails || loadingUpdate ? (
          <Loader />
        ) : (
          <>
            <FormContainer>
              <Breadcrumb>
                <Breadcrumb.Item>
                  <i
                    onClick={() => {
                      navigate(`/teamlist`);
                    }}
                    className="fas fa-home"
                  ></i>
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() => {
                    navigate(`/team/${teamId}`);
                  }}
                >
                  {team && team.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item>Admin Screen</Breadcrumb.Item>
              </Breadcrumb>

              {team && (
                <Form>
                  <Form.Group controlId="teamName" className="col-sm-12">
                    <Form.Label>Team Name</Form.Label>
                    <Form.Control
                      type="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="speciality" className="col-sm-12">
                    <FormLabel>Specialty</FormLabel>
                    <select
                      className="custom-select"
                      type="name"
                      value={specialty}
                      onChange={(e) => setSpecialty(e.target.value)}
                    >
                      {specialityList.map((specialityItem) => (
                        <option
                          key={specialityItem._id}
                          value={specialityItem._id}
                          disabled={specialityItem.disabled}
                        >
                          {specialityItem.name}
                        </option>
                      ))}
                    </select>
                  </Form.Group>

                  <h1>Cloudinary Account</h1>
                  <Form.Group controlId="cloudName" className="col-sm-12">
                    <Form.Label>Cloud Name</Form.Label>
                    <Form.Control
                      type="name"
                      value={cloudName}
                      onChange={(e) => setCloudName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <Form.Group controlId="uploadPreset" className="col-sm-12">
                    <Form.Label>Upload Preset</Form.Label>
                    <Form.Control
                      type="name"
                      value={uploadPreset}
                      onChange={(e) => setUploadPreset(e.target.value)}
                    ></Form.Control>
                  </Form.Group>

                  <h1>About</h1>
                  <div className="text-dark">
                    <TextEditor text={about} setText={setAbout} />
                  </div>

                  <h1>Members</h1>
                  {message && (
                    <Message
                      children={message}
                      variant="danger"
                      setMessgae={setMessage}
                    />
                  )}

                  {members.map((m) => (
                    <ListGroup key={m._id}>
                      <ListGroupItem className="m-3">
                        <div className="container" key={m._id}>
                          <div className="row">
                            <p className="col">
                              {m.firstName && `${m.firstName} ${m.lastName}`}
                              <br />
                              {m.user}
                            </p>
                          </div>
                          <Row>
                            {userInfo && m.user !== userInfo.email && (
                              <>
                                <FormGroup className="col-sm-4">
                                  <FormCheckInput
                                    checked={m.canEdit}
                                    onChange={() => editChange(m)}
                                    type="checkbox"
                                  ></FormCheckInput>{" "}
                                  <FormLabel>Clinical Pharmacist</FormLabel>{" "}
                                </FormGroup>{" "}
                                <FormGroup className="col-sm-4">
                                  <FormCheckInput
                                    checked={m.isPhysic}
                                    onChange={() => setPhysic(m)}
                                    type="checkbox"
                                  ></FormCheckInput>{" "}
                                  <FormLabel> Physician</FormLabel>{" "}
                                </FormGroup>
                                <FormGroup className="col-sm-4 text-danger">
                                  <i
                                    onClick={() => deleteMember(m)}
                                    className="fas fa-trash-alt pr-2"
                                  ></i>
                                  Delete
                                </FormGroup>
                              </>
                            )}
                          </Row>{" "}
                        </div>
                      </ListGroupItem>
                    </ListGroup>
                  ))}

                  {team && userInfo && team.admin === userInfo.email && (
                    <div className="row">
                      <Form.Group controlId="newMember" className="col">
                        <Form.Label>Add new Member's email</Form.Label>
                        <Form.Control
                          type="email"
                          value={newMember.user}
                          onChange={(e) =>
                            setNewMember({
                              id: Math.random(),
                              email: e.target.value.toLowerCase(),
                              user: e.target.value.toLowerCase(),
                              canEdit: true,
                              isPhysic: false,
                              disabled: false,
                            })
                          }
                        ></Form.Control>
                      </Form.Group>
                      <FormGroup>
                        <Form.Label className="text-dark">.</Form.Label>
                        <Button
                          onClick={() => handleMembers()}
                          className="col"
                          variant="primary"
                        >
                          <i className="fas fa-plus"></i>
                        </Button>
                      </FormGroup>
                    </div>
                  )}

                  <FormGroup>
                    <Button
                      onClick={(e) => submitHandler(e)}
                      variant="primary "
                    >
                      Update
                    </Button>
                  </FormGroup>
                </Form>
              )}
            </FormContainer>
          </>
        )}
      </div>
    </>
  );
};

export default TeamEditScreen;

export const PATIENT_DETAILS_REQUEST = "PATIENT_DETAILS_REQUEST";
export const PATIENT_DETAILS_SUCCESS = "PATIENT_DETAILS_SUCCESS";
export const PATIENT_DETAILS_FAIL = "PATIENT_DETAILS_FAIL";
export const PATIENT_DETAILS_RESET = "PATIENT_DETAILS_RESET";

export const PATIENT_UPDATE_REQUEST = "PATIENT_UPDATE_REQUEST";
export const PATIENT_UPDATE_SUCCESS = "PATIENT_UPDATE_SUCCESS";
export const PATIENT_UPDATE_FAIL = "PATIENT_UPDATE_FAIL";
export const PATIENT_UPDATE_RESET = "PATIENT_UPDATE_RESET";

export const PATIENT_LIST_REQUEST = "PATIENT_LIST_REQUEST";
export const PATIENT_LIST_SUCCESS = "PATIENT_LIST_SUCCESS";
export const PATIENT_LIST_FAIL = "PATIENT_LIST_FAIL";
export const PATIENT_LIST_RESET = "PATIENT_LIST_RESET";

export const INPATIENT_LIST_REQUEST = "INPATIENT_LIST_REQUEST";
export const INPATIENT_LIST_SUCCESS = "INPATIENT_LIST_SUCCESS";
export const INPATIENT_LIST_FAIL = "INPATIENT_LIST_FAIL";
export const INPATIENT_LIST_RESET = "INPATIENT_LIST_RESET";

export const PATIENT_REGISTER_REQUEST = "PATIENT_REGISTER_REQUEST";
export const PATIENT_REGISTER_SUCCESS = "PATIENT_REGISTER_SUCCESS";
export const PATIENT_REGISTER_FAIL = "PATIENT_REGISTER_FAIL";
export const PATIENT_REGISTER_RESET = "PATIENT_REGISTER_RESET";

export const PATIENT_DELETE_REQUEST = "PATIENT_DELETE_REQUEST";
export const PATIENT_DELETE_SUCCESS = "PATIENT_DELETE_SUCCESS";
export const PATIENT_DELETE_FAIL = "PATIENT_DELETE_FAIL";
export const PATIENT_DELETE_RESET = "PATIENT_DELETE_RESET";

import React, { useState } from "react";
import { Button, Toast, ToastBody, ToastHeader } from "react-bootstrap";

const SimpleToast = ({ title, body, cell }) => {
  const [show, setShow] = useState(false);
  const toggleToast = () => setShow(!show);

  return (
    <div onClick={toggleToast}>
      {cell}
      <div
        className={`toast align-items-center text-bg-primary border-0 ${
          show ? "show" : "hide"
        }`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        style={{ position: "auto", zIndex: 1080 }}
      >
        <Toast onClose={toggleToast}>
          <Toast.Header>
            <strong>{title}</strong>
          </Toast.Header>
          <Toast.Body>{body}</Toast.Body>
        </Toast>
      </div>
    </div>
  );
};

export default SimpleToast;

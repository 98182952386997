import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const ScrollUp = () => {
  const [visible, setVisible] = useState(true);

  var onScroll = () => {
    setVisible(document.documentElement.scrollTop === 0);
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return () => {
      return document.removeEventListener("scroll", onScroll);
    };
  }, []);
  return (
    <>
      {!visible && (
        <Button
          className="btn-success"
          id="scroll"
          onClick={() => (document.documentElement.scrollTop = 0)}
          style={{
            width: "50px",
            height: "50px",
            left: "25px",
            position: "fixed",
            bottom: "90px",
            borderRadius: "50px",
            textAlign: "center",
            fontSize: "25px",
            boxShadow: "2px 2px 3px #999",
            zIndex: "100",
          }}
        >
          <i className="fa fa-arrow-up" aria-hidden="true"></i>
        </Button>
      )}
    </>
  );
};

export default ScrollUp;

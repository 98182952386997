import {
  PHYSIC_NOTE_CREATE_FAIL,
  PHYSIC_NOTE_CREATE_REQUEST,
  PHYSIC_NOTE_CREATE_RESET,
  PHYSIC_NOTE_CREATE_SUCCESS,
  PHYSIC_NOTE_DELETE_FAIL,
  PHYSIC_NOTE_DELETE_REQUEST,
  PHYSIC_NOTE_DELETE_RESET,
  PHYSIC_NOTE_DELETE_SUCCESS,
  PHYSIC_NOTE_LIST_REQUEST,
  PHYSIC_NOTE_LIST_RESET,
  PHYSIC_NOTE_LIST_SUCCESS,
  PHYSIC_NOTE_UPDATE_FAIL,
  PHYSIC_NOTE_UPDATE_REQUEST,
  PHYSIC_NOTE_UPDATE_RESET,
  PHYSIC_NOTE_UPDATE_SUCCESS,
} from "../constants/physicConstants";

export const physicNoteCreateReducer = (
  state = { noRequest: true },
  action
) => {
  switch (action.type) {
    case PHYSIC_NOTE_CREATE_REQUEST:
      return { loading: true };
    case PHYSIC_NOTE_CREATE_SUCCESS:
      return { loading: false, newNote: action.payload };
    case PHYSIC_NOTE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case PHYSIC_NOTE_CREATE_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const physicNoteListReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case PHYSIC_NOTE_LIST_REQUEST:
      return { loading: true };
    case PHYSIC_NOTE_LIST_SUCCESS:
      return { loading: false, noteList: action.payload };
    case PHYSIC_NOTE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PHYSIC_NOTE_LIST_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const physicNoteUpdateReducer = (
  state = { noRequest: true },
  action
) => {
  switch (action.type) {
    case PHYSIC_NOTE_UPDATE_REQUEST:
      return { loading: true };
    case PHYSIC_NOTE_UPDATE_SUCCESS:
      return { loading: false, updatedNote: action.payload };
    case PHYSIC_NOTE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PHYSIC_NOTE_UPDATE_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const physicNoteDeleteReducer = (
  state = { noRequest: true },
  action
) => {
  switch (action.type) {
    case PHYSIC_NOTE_DELETE_REQUEST:
      return { loading: true };
    case PHYSIC_NOTE_DELETE_SUCCESS:
      return { loading: false, deletedNote: action.payload };
    case PHYSIC_NOTE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case PHYSIC_NOTE_DELETE_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

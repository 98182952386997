import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, FormGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getPatientDetails, updatePatient } from "../actions/patientActions";
import Message from "./Message";
import Loader from "./Loader";
import { intervalToDuration } from "date-fns";
import {
  PATIENT_DETAILS_RESET,
  PATIENT_UPDATE_RESET,
} from "../constants/patientConstants";

const PatientDetails = ({ patientId, editPermission, team }) => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fourthName, setFourthName] = useState("");
  const [medicaRecordNumber, setMedicalRecordNumber] = useState("");
  const [birthdate, setBirthdate] = useState("");

  let currentAge = birthdate
    ? intervalToDuration({
        start: new Date(birthdate),
        end: new Date(),
      })
    : {
        days: 0,
        hours: 0,
        minutes: 0,
        months: 0,
        seconds: 0,
        years: 0,
      };

  const patientDetails = useSelector((state) => state.patientDetails);
  const { loading, error, patient } = patientDetails;

  const patientUpdateStore = useSelector((state) => state.patientUpdateStore);
  const { updatedPatient } = patientUpdateStore;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updatePatient(
        patientId,
        firstName,
        middleName,
        lastName,
        fourthName,
        medicaRecordNumber,
        birthdate
      )
    );
  };

  useEffect(() => {
    if (updatedPatient) {
      dispatch({ type: PATIENT_DETAILS_RESET });
      dispatch({ type: PATIENT_UPDATE_RESET });
    }
    if (!patient || patient._id !== patientId) {
      dispatch(getPatientDetails(patientId));
    } else {
      setFirstName(patient.firstName);
      setMiddleName(patient.middleName);
      setLastName(patient.lastName);
      setBirthdate(patient.birthdate);
      setFourthName(patient.fourthName);
      setMedicalRecordNumber(patient.medicalRecordNumber);
    }
  }, [patientId, patient, dispatch, updatedPatient]);

  return (
    <>
      {!patient || loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Form onSubmit={submitHandler}>
          <Row className="justify-content-end mr-2 pb-2">
            <p>اسم المريض ثلاثي</p>{" "}
          </Row>
          <Row className="flex-row-reverse">
            <Form.Group controlId="firstName" className=" col-sm-3">
              <Form.Control
                type="name"
                className="text-center"
                placeholder="اسم المريض"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="middleName" className="col-sm-3">
              <Form.Control
                type="name"
                className="text-center"
                placeholder="الاسم الأب"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="lastName" className="col-sm-3">
              <Form.Control
                type="name"
                className="text-center"
                placeholder="اسم الجد"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="fourthName" className="col-sm-3">
              <Form.Control
                type="name"
                className="text-center"
                placeholder="الاسم الرابع"
                value={fourthName}
                onChange={(e) => setFourthName(e.target.value)}
              ></Form.Control>
            </Form.Group>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="birthdate">
                <Row className="form-label justify-content-end mr-2 pb-2">
                  تاريخ الميلاد
                </Row>
                <Form.Control
                  className="text-center"
                  type="date"
                  value={birthdate && birthdate.substring(0, 10)}
                  onChange={(e) => setBirthdate(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group controlId="birthdate">
                <Row className="form-label justify-content-end mr-2 pb-2">
                  الرقم الطبي الموحد
                </Row>
                <Form.Control
                  type="name"
                  className="text-center"
                  placeholder="الرقم الطبي الموحد"
                  value={medicaRecordNumber}
                  onChange={(e) => setMedicalRecordNumber(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center text-bold">
            {currentAge &&
              `Age now is: ${currentAge.years} years, ${currentAge.months} months and ${currentAge.days}
               days`}
          </Row>
          {editPermission && (
            <FormGroup>
              <Button
                className="btn-outline-primary text-white my-3"
                style={{ float: "right" }}
                type="submit"
              >
                Update Patient
              </Button>
            </FormGroup>
          )}
        </Form>
      )}
    </>
  );
};

export default PatientDetails;

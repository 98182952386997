export const ADMISSION_CREATE_REQUEST = "ADMISSION_CREATE_REQUEST";
export const ADMISSION_CREATE_SUCCESS = "ADMISSION_CREATE_SUCCESS";
export const ADMISSION_CREATE_FAIL = "ADMISSION_CREATE_FAIL";
export const ADMISSION_CREATE_RESET = "ADMISSION_CREATE_RESET";

export const ADMISSION_DETAILS_REQUEST = "ADMISSION_DETAILS_REQUEST";
export const ADMISSION_DETAILS_SUCCESS = "ADMISSION_DETAILS_SUCCESS";
export const ADMISSION_DETAILS_FAIL = "ADMISSION_DETAILS_FAIL";
export const ADMISSION_DETAILS_RESET = "ADMISSION_DETAILS_RESET";

export const ADMISSION_LIST_REQUEST = "ADMISSION_LIST_REQUEST";
export const ADMISSION_LIST_SUCCESS = "ADMISSION_LIST_SUCCESS";
export const ADMISSION_LIST_FAIL = "ADMISSION_LIST_FAIL";
export const ADMISSION_LIST_RESET = "ADMISSION_LIST_RESET";

export const ADMISSION_UPDATE_REQUEST = "ADMISSION_UPDATE_REQUEST";
export const ADMISSION_UPDATE_SUCCESS = "ADMISSION_UPDATE_SUCCESS";
export const ADMISSION_UPDATE_FAIL = "ADMISSION_UPDATE_FAIL";
export const ADMISSION_UPDATE_RESET = "ADMISSION_UPDATE_RESET";

export const ADMISSION_DELETE_REQUEST = "ADMISSION_DELETE_REQUEST";
export const ADMISSION_DELETE_SUCCESS = "ADMISSION_DELETE_SUCCESS";
export const ADMISSION_DELETE_FAIL = "ADMISSION_DELETE_FAIL";
export const ADMISSION_DELETE_RESET = "ADMISSION_DELETE_RESET";

export const ADMISSION_RECURRENT_REQUEST = "ADMISSION_RECURRENT_REQUEST";
export const ADMISSION_RECURRENT_SUCCESS = "ADMISSION_RECURRENT_SUCCESS";
export const ADMISSION_RECURRENT_FAIL = "ADMISSION_RECURRENT_FAIL";
export const ADMISSION_RECURRENT_RESET = "ADMISSION_RECURRENT_RESET";

export const UPDATE_ADM_MED_REQUEST = "UPDATE_ADM_MED_REQUEST";
export const UPDATE_ADM_MED_SUCCESS = "UPDATE_ADM_MED_SUCCESS";
export const UPDATE_ADM_MED_FAIL = "UPDATE_ADM_MED_FAIL";
export const UPDATE_ADM_MED_RESET = "UPDATE_ADM_MED_RESET";

export const NEW_ADM_MED_REQUEST = "NEW_ADM_MED_REQUEST";
export const NEW_ADM_MED_SUCCESS = "NEW_ADM_MED_SUCCESS";
export const NEW_ADM_MED_FAIL = "NEW_ADM_MED_FAIL";
export const NEW_ADM_MED_RESET = "NEW_ADM_MED_RESET";

export const DELETE_ADM_MED_REQUEST = "DELETE_ADM_MED_REQUEST";
export const DELETE_ADM_MED_SUCCESS = "DELETE_ADM_MED_SUCCESS";
export const DELETE_ADM_MED_FAIL = "DELETE_ADM_MED_FAIL";
export const DELETE_ADM_MED_RESET = "DELETE_ADM_MED_RESET";

export const SORT_ADM_MED_REQUEST = "SORT_ADM_MED_REQUEST";
export const SORT_ADM_MED_SUCCESS = "SORT_ADM_MED_SUCCESS";
export const SORT_ADM_MED_FAIL = "SORT_ADM_MED_FAIL";
export const SORT_ADM_MED_RESET = "SORT_ADM_MED_RESET";

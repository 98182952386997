const getPageMargins = () => {
  const marginTop = "50px";
  const marginRight = "30px";
  const marginBottom = "50px";
  const marginLeft = "30px";
  return `
    @media print {
      @page {
        margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important;
      }
    }
  `;
};

export default getPageMargins;

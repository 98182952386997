import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { intervalToDuration } from "date-fns";
import Meta from "../components/Meta";
import { Container, Row, Tab, Tabs } from "react-bootstrap";
import { getAdmissionDetails } from "../actions/admissionActions";
import { useDispatch, useSelector } from "react-redux";
import ReviewList from "../components/ReviewList";
import FormContainer from "../components/FormContainer";
import ReviewForm from "../components/ReviewForm";
import { listAdmissionReviews } from "../actions/reviewActions";
import { getTeamDetails } from "../actions/teamActions";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getPatientDetails } from "../actions/patientActions";
import { listDrugErrTypes } from "../actions/errTypeActions";
import {
  ADMISSION_REVIEWS_RESET,
  REVIEW_CREATE_RESET,
  REVIEW_DELETE_RESET,
} from "../constants/reviewConstants";
import {
  ADMISSION_DETAILS_RESET,
  UPDATE_ADM_MED_RESET,
} from "../constants/admissionConstants";
import { PATIENT_DETAILS_RESET } from "../constants/patientConstants";
import AdmissionMedcations from "../components/AdmissionMedications";
import Loader from "../components/Loader";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
import Message from "../components/Message";
import AdmGallery from "./AdmGallery";
import { listTradeLabels } from "../actions/drugActions";
import dischargeStatusList from "../data/dischargeStatusList";
import PhysicNoteList from "../components/PhysicNoteList";
import PhysicNoteForm from "../components/PhysicNoteForm";
import { listAdmissionNotes } from "../actions/physNoteAction";
import AdmMedList from "../components/AdmMedList";

const ReviewListScreen = () => {
  const params = useParams();
  const admissionId = params.id;

  const [searchParams, setSearchParams] = useSearchParams();
  const [labeledReview, setLabeledReview] = useState(
    searchParams.get("review")
  );

  const [labeledNote, setLabeledNote] = useState(searchParams.get("note"));

  const [patientId, setPatientId] = useState("");

  const [synching, setSynching] = useState(false);
  const [synchedReviews, setSynchedReviews] = useState();

  const [synchingNotes, setSynchingNotes] = useState(false);
  const [synchedNotes, setSynchedNotes] = useState();

  const errTypes = useSelector((state) => state.errTypes);
  const { noRequest: noErrTypeRequest, errTypeList } = errTypes;

  const tradeLabelsStore = useSelector((state) => state.tradeLabelsStore);
  const { noRequest: noTradeLabelRequest } = tradeLabelsStore;

  const admissionDetails = useSelector((state) => state.admissionDetails);
  const {
    noRequest: noAdmissionRequest,
    admission,
    errors: admDetailsErr,
  } = admissionDetails;

  const patientDetails = useSelector((state) => state.patientDetails);
  const { noRequest: noPatientRequest, patient } = patientDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const teamDetails = useSelector((state) => state.teamDetails);
  const { noRequest: noTeamRequest, team } = teamDetails;

  const reviewsByAdmissionStore = useSelector(
    (state) => state.reviewsByAdmissionStore
  );
  const { noRequest } = reviewsByAdmissionStore;

  const physicNoteListStore = useSelector((state) => state.physicNoteListStore);
  const {
    loading: loadAdmNote,
    noRequest: noNotesRequest,
    noteList,
    error,
  } = physicNoteListStore;

  let editPermission =
    team && userInfo
      ? team.members.find((m) => m.user === userInfo.email) &&
        team.members.find((m) => m.user === userInfo.email).canEdit
      : false;

  let physicEditPerm =
    team && userInfo
      ? team.members.find((m) => m.user === userInfo.email) &&
        team.members.find((m) => m.user === userInfo.email).isPhysic
      : false;

  let currentAge =
    patient && patient.birthdate
      ? intervalToDuration({
          start: new Date(patient.birthdate),
          end: new Date(),
        })
      : {
          days: 0,
          hours: 0,
          minutes: 0,
          months: 0,
          seconds: 0,
          years: 0,
        };

  let postDischargeDuration =
    admission && admission.dischargeDate
      ? intervalToDuration({
          start: new Date(admission.dischargeDate),
          end: new Date(),
        })
      : {
          days: 0,
          hours: 0,
          minutes: 0,
          months: 0,
          seconds: 0,
          years: 0,
        };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!localStorage.tradeLabels && noTradeLabelRequest) {
    dispatch(listTradeLabels());
  }

  let tradeLabels =
    localStorage.tradeLabels && JSON.parse(localStorage.tradeLabels);

  const location = useLocation();

  useEffect(() => {
    if (userInfo) {
      if (!admission || admission._id !== admissionId) {
        dispatch({ type: ADMISSION_DETAILS_RESET });
        dispatch({ type: REVIEW_CREATE_RESET });
        dispatch({ type: REVIEW_DELETE_RESET });
        dispatch({ type: ADMISSION_REVIEWS_RESET });
        dispatch({ type: UPDATE_ADM_MED_RESET });

        setSynchedReviews();
        dispatch({ type: PATIENT_DETAILS_RESET });
        if (noAdmissionRequest) {
          dispatch(getAdmissionDetails(admissionId));
        }
      } else {
        if (noTeamRequest) {
          dispatch(getTeamDetails(admission.team));
        }

        if (noPatientRequest) {
          dispatch(getPatientDetails(admission.patient));
        }
        if (noNotesRequest) {
          dispatch(listAdmissionReviews({ admissionId, team: admission.team }));
        }

        if (noNotesRequest) {
          dispatch(listAdmissionNotes({ admissionId, team: admission.team }));
        }

        setPatientId(admission.patient);
      }

      if (noErrTypeRequest) {
        dispatch(listDrugErrTypes());
      }
    } else {
      navigate(`/?returnFrom=${location.pathname}`);
    }
  }, [
    dispatch,
    userInfo,
    noTeamRequest,
    noPatientRequest,
    errTypeList,
    errTypeList,
    noNotesRequest,
    admissionId,
    admission,
    noRequest,
    patientId,
  ]);
  return (
    <>
      <Meta title={patient && patient.firstName} />
      {admDetailsErr && <Message children={admDetailsErr} />}

      {!admission || !team || !patient ? (
        <FormContainer>
          <Skeleton height="50px" className="mb-5" />

          <Loader />
        </FormContainer>
      ) : (
        <FormContainer>
          <>
            <BreadcrumbComponent
              titles={[
                { idx: "1", label: "Home", path: `/`, active: false },
                {
                  idx: "2",
                  label: `${team.name}`,
                  path: `/team/${team._id}`,
                  active: false,
                },
                {
                  idx: "3",
                  label: `Patients`,
                  path: `/patientlist/${team._id}`,
                  active: false,
                },
                {
                  idx: "4",
                  label: `${patient.firstName} ${patient.middleName} ${patient.lastName}`,
                  path: `/patient/${patient._id}`,
                  active: false,
                },
                {
                  idx: "5",
                  label: "Admission Details",
                  path: `/admissions/${admission._id}`,
                  active: true,
                },
              ]}
            />

            <div>
              <hr style={{ Color: "blue" }} />

              <h6 className="row lead pl-3">
                {admission.admissionDate &&
                  admission.admissionDate.substring(0, 10)}
              </h6>
              <div
                className="text-primary"
                dangerouslySetInnerHTML={{
                  __html: `${admission.admissionDetails}`,
                }}
              />
              <h6 className="row lead p-3 text-danger">
                {admission.dischargeDate &&
                  `Discharged: ${admission.dischargeDate.substring(
                    0,
                    10
                  )} i.e. from ${postDischargeDuration.years} Ys ${
                    postDischargeDuration.months
                  } ms ${postDischargeDuration.days} days`}
              </h6>

              {admission.dischargeStatus && (
                <h6 className="row lead p-3 text-danger">
                  {`Discharge Status: ${
                    dischargeStatusList.find(
                      (s) =>
                        s._id.toString() ===
                        admission.dischargeStatus.toString()
                    ).label
                  }`}
                </h6>
              )}

              <Row className="justify-content-center font-weight-bold text-dark">
                {currentAge &&
                  `Age now is: ${currentAge.years} years, ${currentAge.months} months and ${currentAge.days}
               days`}
              </Row>
              <hr style={{ backgroundColor: "blue" }} />
            </div>

            <Container>
              <Tabs id="controlled-tab-example" defaultActiveKey="reviews">
                <Tab
                  eventKey="physicNote"
                  title={
                    <>
                      <span className="physician-note">Physician Note </span>
                      <span className="badge bg-success text-white">
                        {noteList ? noteList.length : 0}
                      </span>
                    </>
                  }
                >
                  <div>
                    <PhysicNoteList
                      admissionId={admissionId}
                      patientId={patientId}
                      teamId={admission.team}
                      patient={patient}
                      admission={admission}
                      labeledNote={labeledNote}
                      setLabeledNote={setLabeledNote}
                      synchingNotes={synchingNotes}
                      synchedNotes={synchedNotes}
                      setSynchedNotes={setSynchedNotes}
                    />
                    {physicEditPerm && (
                      <PhysicNoteForm
                        admissionId={admissionId}
                        patientId={patientId}
                        teamId={admission.team}
                        errTypeList={errTypeList}
                      />
                    )}
                  </div>
                </Tab>
                <Tab eventKey="reviews" title="Clinical Pharmacist">
                  <div>
                    <ReviewList
                      tradeLabels={tradeLabels}
                      admissionId={admissionId}
                      patientId={patientId}
                      birthdate={patient.birthdate}
                      teamId={admission.team}
                      patient={patient}
                      admission={admission}
                      errTypeList={errTypeList}
                      labeledReview={labeledReview}
                      setLabeledReview={setLabeledReview}
                      synching={synching}
                      synchedReviews={synchedReviews}
                      setSynchedReviews={setSynchedReviews}
                    />
                    {editPermission && (
                      <ReviewForm
                        admissionId={admissionId}
                        patientId={patientId}
                        teamId={admission.team}
                        errTypeList={errTypeList}
                      />
                    )}
                  </div>
                </Tab>
                <Tab eventKey="med" title={<>Medication </>}>
                  <AdmMedList admissionId={admissionId} />
                </Tab>
                {/* <Tab eventKey="medications" title={<>Med. </>}>
                  <AdmissionMedcations
                    admissionId={admissionId}
                    editPermission={editPermission}
                  />
                </Tab> */}
                <Tab
                  eventKey="Gallery"
                  title={
                    <>
                      <span className="physician-note">Gallery </span>
                      <span className="badge bg-success text-white">
                        {admission.gallery ? admission.gallery.length : 0}
                      </span>
                    </>
                  }
                >
                  <AdmGallery admissionId={admissionId} />
                </Tab>
              </Tabs>
            </Container>
          </>
        </FormContainer>
      )}
    </>
  );
};

export default ReviewListScreen;

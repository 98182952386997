export const USER_ERR_TEMP_REQUEST = "USER_ERR_TEMP_REQUEST";
export const USER_ERR_TEMP_SUCCESS = "USER_ERR_TEMP_SUCCESS";
export const USER_ERR_TEMP_FAIL = "USER_ERR_TEMP_FAIL";
export const USER_ERR_TEMP_RESET = "USER_ERR_TEMP_RESET";

export const USER_ERR_TEMP_ADD_REQUEST = "USER_ERR_TEMP_ADD_REQUEST";
export const USER_ERR_TEMP_ADD_SUCCESS = "USER_ERR_TEMP_ADD_SUCCESS";
export const USER_ERR_TEMP_ADD_FAIL = "USER_ERR_TEMP_ADD_FAIL";
export const USER_ERR_TEMP_ADD_RESET = "USER_ERR_TEMP_ADD_RESET";

export const USER_ERR_TEMP_DELETE_REQUEST = "USER_ERR_TEMP_DELETE_REQUEST";
export const USER_ERR_TEMP_DELETE_SUCCESS = "USER_ERR_TEMP_DELETE_SUCCESS";
export const USER_ERR_TEMP_DELETE_FAIL = "USER_ERR_TEMP_DELETE_FAIL";
export const USER_ERR_TEMP_DELETE_RESET = "USER_ERR_TEMP_DELETE_RESET";

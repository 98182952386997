import {
  TRADE_LABELS_FAIL,
  TRADE_LABELS_REQUEST,
  TRADE_LABELS_SUCCESS,
  TRADE_LABELS_RESET,
  ERR_TEMPS_SUCCESS,
  ERR_TEMPS_FAIL,
  ERR_TEMPS_RESET,
  ERR_TEMPS_REQUEST,
  NEW_DRUG_REQUEST,
  NEW_DRUG_SUCCESS,
  NEW_DRUG_FAIL,
  NEW_DRUG_RESET,
  ROOT_BY_TRADEID_REQUEST,
  ROOT_BY_TRADEID_SUCCESS,
  ROOT_BY_TRADEID_FAIL,
  ROOT_BY_TRADEID_RESET,
  UPDATE_ROOT_REQUEST,
  UPDATE_ROOT_SUCCESS,
  UPDATE_ROOT_FAIL,
  UPDATE_ROOT_RESET,
  LIST_DRUG_ROOTS_RESET,
  LIST_DRUG_ROOTS_SUCCESS,
  LIST_DRUG_ROOTS_FAIL,
  LIST_DRUG_ROOTS_REQUEST,
} from "../constants/drugConstants";

export const drugsReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case TRADE_LABELS_REQUEST:
      return { loading: true };
    case TRADE_LABELS_SUCCESS:
      return {
        loading: false,
        // tradeLabels: action.payload,
      };
    case TRADE_LABELS_FAIL:
      return { loading: false, error: action.payload };
    case TRADE_LABELS_RESET:
      return {};
    default:
      return state;
  }
};

export const errTempsReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case ERR_TEMPS_REQUEST:
      return { loading: true };
    case ERR_TEMPS_SUCCESS:
      return {
        loading: false,
        errTemps: action.payload,
      };
    case ERR_TEMPS_FAIL:
      return { loading: false, error: action.payload };
    case ERR_TEMPS_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

export const newDrugReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case NEW_DRUG_REQUEST:
      return { loading: true };
    case NEW_DRUG_SUCCESS:
      return {
        loading: false,
        drug: action.payload,
      };
    case NEW_DRUG_FAIL:
      return { loading: false, error: action.payload };
    case NEW_DRUG_RESET:
      return {};
    default:
      return state;
  }
};

export const rootByTradeIdReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case ROOT_BY_TRADEID_REQUEST:
      return { loading: true };
    case ROOT_BY_TRADEID_SUCCESS:
      return {
        loading: false,
        drug: action.payload,
      };
    case ROOT_BY_TRADEID_FAIL:
      return { loading: false, error: action.payload };
    case ROOT_BY_TRADEID_RESET:
      return {};
    default:
      return state;
  }
};

export const updateRootReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case UPDATE_ROOT_REQUEST:
      return { loading: true };
    case UPDATE_ROOT_SUCCESS:
      return {
        loading: false,
        drug: action.payload,
      };
    case UPDATE_ROOT_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_ROOT_RESET:
      return {};
    default:
      return state;
  }
};

export const listDrugRootsReducer = (state = { noRequest: true }, action) => {
  switch (action.type) {
    case LIST_DRUG_ROOTS_REQUEST:
      return { loading: true };
    case LIST_DRUG_ROOTS_SUCCESS:
      return {
        loading: false,
        drugRoots: action.payload,
      };
    case LIST_DRUG_ROOTS_FAIL:
      return { loading: false, error: action.payload };
    case LIST_DRUG_ROOTS_RESET:
      return { noRequest: true };
    default:
      return state;
  }
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { getRevMedDashboard } from "../actions/reviewActions";
import SimpleToast from "./SimpleToast";
import Skeleton from "react-loading-skeleton";

const AdmMedList = ({ admissionId }) => {
  const [dayList, setDayList] = useState();
  const [medications, setMedications] = useState();

  const revMedDashboardStore = useSelector(
    (state) => state.revMedDashboardStore
  );
  const { loading, medDashboard } = revMedDashboardStore;

  const dispatch = useDispatch();

  const showDashboard = () => {
    if (!medDashboard || medDashboard.admission !== admissionId) {
      dispatch(getRevMedDashboard(admissionId));
    }
  };
  useEffect(() => {
    if (medDashboard) {
      setDayList(medDashboard.dayList);
      setMedications(medDashboard.zMedications);
    }
  }, [medDashboard]);

  return (
    <>
      {loading && <Skeleton height="50px" className="mb-5" count={6} />}
      {!medications ? (
        <Col>
          <Row className="mt-5 justify-content-center">
            <Button onClick={() => showDashboard()}>Show Dashboard</Button>
          </Row>
        </Col>
      ) : (
        <div className=" overflow-auto flex-nowrap">
          {dayList && medications && (
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Medication</th>
                  {dayList &&
                    dayList.map((d) => (
                      <th className="px-2 bg-light sticky-top top-0">
                        {d.substring(5, 10)}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {medications.map((m, index = 0) => (
                  <tr key={index + 1}>
                    {" "}
                    <td
                      style={{
                        position: "sticky",
                      }}
                    >
                      {m.medication}
                    </td>
                    {m.days.map((med) => (
                      <td className="text-primary">
                        {med.isChecked ? (
                          <SimpleToast
                            title={med.zType}
                            body={med.zResp}
                            cell={med.dayDate.substring(5, 10)}
                          />
                        ) : (
                          ""
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      )}
    </>
  );
};

export default AdmMedList;

import React from "react";
import getPageMargins from "../components/getPageMargins";
import resTypes from "../data/resTypes";

export const ComponentToPrint = React.forwardRef(
  ({ reviews, startDate, endDate }, ref) => {
    return (
      <div
        className="bg-white text-dark text-bold"
        style={{ fontFamily: "Centaur" }}
        ref={ref}
      >
        <div>
          <style>{getPageMargins()}</style>
          <div>
            <h4>Clinical Pharmacy Team.</h4>
          </div>
          <div className="row" style={{ paddingLeft: "20%" }}>
            <h3>
              Collected Intervention Report <br />
              {`From ${startDate} To ${endDate}`}
            </h3>
          </div>

          {reviews &&
            startDate &&
            endDate &&
            reviews.map((p) => (
              <div key={p._id}>
                <div>
                  <hr style={{ backgroundColor: "black", size: "30px" }} />
                </div>
                <h2 className="row text-bold" style={{ paddingLeft: "10%" }}>
                  {`${p.order}- Patient Name:  ${p.firstName} XXXXXX XXXXXX`}
                </h2>

                {p.admissions.map((a) => (
                  <ul key={a._id}>
                    {" "}
                    <li>
                      <h2>
                        {a.admissionDate &&
                          `Admission Date: ${a.admissionDate.substring(0, 10)}`}
                      </h2>
                    </li>
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: `${a.admissionDetails}`,
                      }}
                    />
                    {a.dischargeDate && (
                      <li>
                        {" "}
                        <h2>{`Discharge Date: ${a.dischargeDate.substring(
                          0,
                          10
                        )}`}</h2>
                      </li>
                    )}
                    {a.reviews.map((r, index = 0) => (
                      <div key={r._id}>
                        <div>
                          <hr
                            style={{
                              backgroundColor: "black",
                              size: "30px",
                              width: "50%",
                            }}
                          />
                        </div>
                        <ul>
                          <li>
                            <h2>
                              {r.reviewDate &&
                                `${
                                  index + 1
                                }-Review Date: ${r.reviewDate.substring(
                                  0,
                                  10
                                )}, Edited by: ${r.userName}`}
                            </h2>
                          </li>
                        </ul>
                        {r.clinicalNote && (
                          <h2
                            dangerouslySetInnerHTML={{
                              __html: `Progression Note: ${r.clinicalNote}`,
                            }}
                          />
                        )}

                        {r.drugErrs.length > 0 && (
                          <div className="table table-hover text-dark mr-5 ">
                            <thead>
                              <tr>
                                <th scope="col-3">The Error</th>
                                <th scope="col-3">Drug of error</th>
                                <th scope="col-4">Description</th>
                                <th scope="col-2">Response</th>
                              </tr>
                            </thead>
                            {r.drugErrs.map((e) => (
                              <tbody key={e._id}>
                                <tr class="table-active">
                                  <td>{e.errType}</td>
                                  <td>{e.errDrug}</td>
                                  <td>
                                    <div className="row">
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: ` ${e.errNote}`,
                                        }}
                                      />
                                    </div>
                                    <div className="row">
                                      {e.drugTags.map((t, o = 0) => (
                                        <b key={o}>{`#${t.name} `}</b>
                                      ))}
                                    </div>
                                  </td>

                                  <td>
                                    {resTypes &&
                                      resTypes.find(
                                        (t) => t._id.toString() === e.errRes
                                      ) &&
                                      resTypes.find(
                                        (t) => t._id.toString() === e.errRes
                                      ).label}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </ul>
                ))}
              </div>
            ))}
        </div>
      </div>
    );
  }
);
